
const SmsPayTable = ({ saccoHistoryData }) => {
  console.log(saccoHistoryData);
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-hover align-middle">
        <thead className="table-dark">
          <tr>
            <th>From</th>
            <th>To</th>
            <th>Amount</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {saccoHistoryData.map((item) => (
            <tr key={item?.id}>
              <td>{item?.from}</td>
              <td>{item?.to}</td>
              <td>{item?.amount_paid}</td>
              <td>{item?.timestamp}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SmsPayTable;
