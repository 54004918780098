import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import AdminFooter from "./components/admin_footer";
import AdminSidebar from "./components/admin_sidebar";
import AdminHeader from "./components/admin_header";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../middlewares/hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import ProgressBar from "@badrap/bar-of-progress";
import jsPDF from "jspdf";
import "jspdf-autotable";
import toast, { Toaster } from "react-hot-toast";
import Loader from "services/loaders/loader";
const UssdIncomeReport = () => {
  //const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  //const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [startDate, setStartDate] = useState(""); // State to hold the selected start date
  const [endDate, setEndDate] = useState(""); // State to hold the selected end date
  const [isGenerating, setIsGenerating] = useState(false);
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // const [data, setData] = useState([]);
  const controller = new AbortController();
  const [allUssdTransactions, setAllUssdTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [totalAmount, setTotalAmount] = useState([]);
  // getting ussd income
  const getAllUssdTransactions = async (startDate, endDate) => {
    try {
      progress.start();
      setDisabled(true);
      setLoaderVisible(true);
      const requestData = {
        start_date: startDate,
        end_date: endDate,
      };
      const response = await axiosPrivate.post(
        `/ussd_transactions_all`,
        requestData,
        {
          signal: controller.signal,
        }
      );
      //console.log(response.data.data.ussdTransactions);
      const ussdTransactions = response.data.data.ussdTransactions;
      console.log(ussdTransactions);

      // Calculate the total amount
      const calculatedTotalAmount = ussdTransactions.reduce(
        (sum, transaction) => {
          return sum + parseFloat(transaction.amount);
        },
        0
      );

      // Set the total amount in the state
      setTotalAmount(calculatedTotalAmount);

      setAllUssdTransactions(ussdTransactions);
      // setAllUssdTransactions(response.data.data.ussdTransactions);
      progress.finish();
      setDataLoaded(true);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };
  // Function to calculate the number of SMS messages in a given message text
  useEffect(
    () => {
      //console.log(messages);
      // Function to sort the messages in ascending order using the timestamp
      const sortByTimestampDesc = (a, b) =>
        new Date(b.timestamp) - new Date(a.timestamp);
      const newFilteredTransactions = allUssdTransactions
        .filter((transaction) => {
          const transactionDate = new Date(transaction.timestamp);
          return (
            transactionDate >= new Date(startDate) &&
            transactionDate <= new Date(endDate)
          );
        })
        .sort(sortByTimestampDesc); // Sort the messages by timestamp in ascending order

      setFilteredTransactions(newFilteredTransactions);
      console.log(newFilteredTransactions);
    },
    // eslint-disable-next-line
    [startDate, endDate, allUssdTransactions]
  );
  useEffect(() => {
    reset();
    // eslint-disable-next-line
  }, []);
  const onSubmit = async ({ start_date, end_date }) => {
    setIsGenerating(true);
    setStartDate(start_date); // Update the state with selected start date
    setEndDate(end_date); // Update the state with selected end date
    setLoaderVisible(true);
    try {
      await getAllUssdTransactions(start_date, end_date);
      setIsReportGenerated(true);
      //navigating to the url with the sacco id
      //navigate(`/ussd_income_report`)
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsGenerating(false);
    }
  };
  //Downloading the report as a pdf
  const downloadReportAsPDF = () => {
    if (isReportGenerated) {
      setIsGenerating(false);
      try {
        const doc = new jsPDF("p", "mm", "a4");
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.setFontSize(14);
        doc.text("All USSD Transactions", pageWidth / 2, 10, {
          align: "center",
        });
        doc.setFontSize(10);
        //doc.text("Sacco Name: " + selectedSaccoName, 10, 10, { align: "left" });
        doc.text("Start Date: " + startDate, pageWidth - 10, 10, {
          align: "right",
        });
        doc.text("End Date: " + endDate, pageWidth - 10, 20, {
          align: "right",
        });
        const columns = [
          { header: "Client Name", dataKey: "client" },
          { header: "Account No", dataKey: "account_number" },
          { header: "Amount", dataKey: "amount" },
          { header: "Charge", dataKey: "charge" },
          { header: "Sacco Name", dataKey: "sacco_name" },
          { header: "Timestamp", dataKey: "timestamp" },
        ];
        const rows = filteredTransactions.map((transaction) => {
          return {
            client: transaction.client,
            account_number: transaction.account_number,
            amount: transaction.amount,
            charge: transaction.charge,
            sacco_name: transaction.sacco_name,
            timestamp: transaction.timestamp,
          };
        });
        rows.push({
          client: "USSD Total Amount",
          account_number: "",
          amount: formatAmount(totalAmount),
          charge: "",
          sacco_name: "",
          timestamp: "",
        });
        // adding the total total amount
        rows.push({
          client: "Total Ussd Count",
          account_number: "",
          amount: filteredTransactions.length,
          charge: "",
          sacco_name: "",
          timestamp: ""
        });
        doc.autoTable({
          head: [columns.map((column) => column.header)],
          body: rows.map((row) => columns.map((column) => row[column.dataKey])),
          startY: 30, // adjust this value based on your text's y-coordinate
          styles: { fontSize: 10 },
          didDrawPage: function (data) {
            // Add page number to each page
            doc.setFontSize(10);
            var pageNumber = doc.internal.getNumberOfPages();
            var pageCenter = doc.internal.pageSize.width / 2; // Calculate the center of the page
            doc.text(
              "Page " + pageNumber,
              pageCenter,
              doc.internal.pageSize.height - 10,
              { align: "center" }
            );
          },
          didParseCell: function (data) {
            const rows = data.table.body;
            if (data.row.index === rows.length - 1) {
              // Add border to the last row
              data.cell.styles.lineWidth = 0.1;
              data.cell.styles.lineColor = [0, 0, 0];
            } else {
              // Add borders to other cells
              data.cell.styles.lineWidth = 0.1;
              data.cell.styles.lineColor = [0, 0, 0];
              data.cell.styles.borders = "LTRB"; // All borders: left, top, right, bottom
            }
          },
        });

        doc.save(`all_sacco_ussd_report.pdf`);
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    }
  };
  const formatAmount = (number) => {
    // Convert to a number if not already a number
    const num = Number(number);
    // Check if num is a number
    if (isNaN(num)) {
      throw new Error(`${number} is not a number`);
    }
    return num.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,");
  };

  return (
    <>
      {/* <Toaster /> */}
      <Toaster />
      {/* .............header...................... */}
      <AdminHeader />
      {/* .............header end..................... */}

      <div className="d-flex align-items-stretch">
        {/* .............siderbar...................... */}
        <AdminSidebar />
        {/* ..............sidebar end..................... */}
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        All USSD Transactions Report{" "}
                      </h2>
                    </div>
                    <div className="card-body mt-3 mb-3">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <form
                            className="row needs-validation"
                            id="generalLegerForm"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="form-group col-md-3">
                              <label className="form-label">
                                Select Start Date
                              </label>
                              <input
                                type="date"
                                placeholder="Select Start Date"
                                className="form-control form-control-md input-text shadow border-0"
                                {...register("start_date", { required: true })}
                              />
                              {errors?.start_date?.type === "required" && (
                                <p className="text-small m-0 text-danger help-block">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-3">
                              <label className="form-label">
                                Select End Date
                              </label>
                              <input
                                type="date"
                                placeholder="Select End Date"
                                className="form-control form-control-md input-text shadow border-0"
                                {...register("end_date", { required: true })}
                              />
                              {errors?.end_date?.type === "required" && (
                                <p className="text-small m-0 text-danger help-block">
                                  This field is required
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-3 pt-3 mt-3">
                              <Button
                                type="submit"
                                className="btn btn-primary text-center rounded-0"
                                disabled={isGenerating || disabled} // disable the button while generating
                              >
                                {!isGenerating
                                  ? "Generate"
                                  : "Please Wait ....."}
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="mt-3">
                        {loaderVisible && !dataLoaded ? (
                          <Loader />
                        ) : (
                          <div className="d-flex justify-content-between mb-3">
                            <div>
                              <h5 className="text-center">All USSD Transactions Report</h5>
                            </div>
                            <div>
                              <span className="mr-3">
                                Start Date: {startDate}
                              </span>
                              <span>End Date: {endDate}</span>
                            </div>
                          </div>
                        )}

                        {/* Table to display messages */}
                        <table className="table table-bordered table-striped table responsive">
                          <thead>
                            <tr>
                              <th>Client Name</th>
                              <th>Account No</th>
                              <th>Amount</th>
                              <th>Charge</th>
                              <th>Account Type</th>
                              <th>Sacco Name</th>
                              <th>Timestamp</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredTransactions.map((transaction, index) => (
                              <tr key={index}>
                                <td>{transaction.client}</td>
                                <td>{transaction.account_number}</td>
                                <td>{transaction.amount}</td>
                                <td>{transaction.charge}</td>
                                <td>{transaction.account}</td>
                                <td>{transaction.sacco_name}</td>
                                <td>{transaction.timestamp}</td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            {isReportGenerated && (
                              <>
                                <tr>
                                  <td colSpan="6">Total Amount:</td>
                                  <td className="font-weight-bold">
                                    {formatAmount(totalAmount)}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="6">Total USSD Count:</td>
                                  <td className="font-weight-bold">
                                    {filteredTransactions.length}
                                  </td>
                                </tr>
                              </>
                            )}
                          </tfoot>
                          <tfoot>{isReportGenerated && <tr></tr>}</tfoot>
                        </table>
                        <div className="d-flex align-items-center">
                          <div className="ml-auto">
                            <Button
                              className="btn btn-primary"
                              onClick={downloadReportAsPDF}
                              disabled={!isReportGenerated}
                            >
                              Download Report
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* footer............................ */}
          <AdminFooter />
          {/* footer end............................ */}
        </div>
      </div>
    </>
  );
};

export default UssdIncomeReport;
