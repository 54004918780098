import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import toast, { Toaster } from "react-hot-toast";
import useAxiosPrivate from "../../middlewares/hooks/useAxiosPrivate";

const OnboardSacco = ({ showModal, handleCloseModal, getSaccos }) => {

  const [disabled, setDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSave = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const controller = new AbortController();
      const response = await axiosPrivate.post(
        "/saccos",
        data,
        {
          signal: controller.signal,
        }
      );

      toast.success(response?.data?.messages);
      progress.finish();
      setDisabled(false);
      getSaccos();
      handleCloseModal();
      reset();
      console.log(data);
    } catch (error) {
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
        setDisabled(false);
      } else {
        console.log(error?.response);
        progress.finish();
        setDisabled(false);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };


  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <form noValidate onSubmit={handleSubmit(onSave)}>
          <Modal.Header>
            <Modal.Title className="text-center"> Onboard Sacco </Modal.Title>
          </Modal.Header>
          <Modal.Body className="row mx-auto">
            <div className="form-group col-md-6 input-container">
              <label htmlFor="sacconame" className="form-label">
                Sacco Name
              </label>
              <input
                type="text"
                name="sacco_name"
                placeholder="sacconame  *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("sacco_name", {
                  required: true,
                })}
              />
              {errors?.sacco_name?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter Sacco name
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="shortname" className="form-label">
                Sacco Short Name
              </label>
              <input
                type="text"
                name="sacco_short_name"
                placeholder="shortname  *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("sacco_short_name", {
                  required: true,
                })}
              />
              {errors?.sacco_short_name?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter short Name
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="contact" className="form-label">
                Sacco Contact
              </label>
              <input
                type="number"
                name="sacco_contact"
                placeholder="Contact  *"
                maxLength={10}
                className="form-control border-0 shadow form-control-md input-text"
                {...register("sacco_contact", {
                  required: true,
                })}
              />
              {errors?.sacco_contact?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter sacco contact
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="email" className="form-label">
                Sacco Email Address
              </label>
              <input
                type="email"
                name="sacco_emails"
                placeholder="Email Address"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("sacco_emails", {
                  pattern: { value: /^\S+@\S+$/i },
                })}
              />
              {errors?.sacco_emails?.type === "pattern" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter valid email
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="address" className="form-label">
                Sacco Location
              </label>
              <input
                type="text"
                name="sacco_location"
                placeholder="Address  *"
                className="form-control border-0 shadow form-control-md input-text"
                {...register("sacco_location", {
                  required: true,
                })}
              />
              {errors?.sacco_location?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter Sacco location
                </p>
              )}
            </div>
            <div className="form-group col-md-6 input-container">
              <label htmlFor="contact" className="form-label">
                Sacco Contacts
              </label>
              <input
                type="number"
                name="sacco_contacts"
                placeholder="Contact  *"
                maxLength={10}
                className="form-control border-0 shadow form-control-md input-text"
                {...register("sacco_contacts", {
                  required: true,
                })}
              />
              {errors?.sacco_contacts?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter sacco Contacts
                </p>
              )}
            </div>
            <div className="form-group col-md-4 input-container">
              <label htmlFor="dateOfJoin" className="form-label">
                Date Of Registration
              </label>
              <input
                type="date"
                name="sacco_registration_date"
                placeholder="Enter Date Of Registration *"
                className="form-control border-1 form-control-md input-text"
                {...register("sacco_registration_date", {
                  required: true,
                })}
              />
              {errors?.sacco_registration_date?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter a date of registration
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="Button"
              className="btn btn-secondary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="btn btn-primary"
              disabled={disabled}
            >
              {!disabled && "Save"} {disabled && "Please Wait ....."}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default OnboardSacco;
