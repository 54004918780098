import { useState, useEffect } from "react";
import AdminFooter from "./components/admin_footer";
import AdminSidebar from "./components/admin_sidebar";
import AdminHeader from "./components/admin_header";
import ProgressBar from "@badrap/bar-of-progress";
import Pagination from "./pagination";
import fileDownload from "js-file-download";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../middlewares/hooks/useAxiosPrivate";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import toast, { Toaster } from "react-hot-toast";

const SaccoSavingsReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isPDFDownloading, setIsPDFDownloading] = useState(false);
  const [isCSVDownloading, setIsCSVDownloading] = useState(false);
  const [progress, setProgress] = useState(null);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [depositTransactions, setDepositTransactions] = useState([]);
  const [totalDepositCount, setTotalDepositCount] = useState(0);
  const [saccoName, setSaccoName] = useState([]);
  const [ussdAmount, setUssdAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;
  const [generateButtonClicked, setGenerateButtonClicked] = useState(false);
  const [saccosName, setSaccosName] = useState("");

  const fetchSavingsTransactions = async () => {
    if (generateButtonClicked) {
      if (progress) {
        progress.start();
      }
      try {
        const requestData = {
          start_date: startDate,
          end_date: endDate,
        };
        const controller = new AbortController();
        const response = await axiosPrivate.post(
          `/savings/${params.id}/page/${currentPage}`,
          requestData,
          { signal: controller.signal }
        );
        setDepositTransactions(response?.data?.data?.depositTransactions);
        setTotalDepositCount(response?.data?.data?.deposit_count);
        setUssdAmount(response?.data?.data?.total_amount);
        setSaccoName(response?.data?.data?.depositTransactions[0]?.sacco_name);
        if (progress) {
          progress.finish();
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        } else {
          console.error("Error fetching USSD transactions:", error);
          toast.error("No info found", {
            style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
          });
        }
        if (progress) {
          progress.finish();
        }
      }
    }
  }; 
  const controller = new AbortController();
  const getSaccosName = async () => {
    try {
      const response = await axiosPrivate.get(`/saccos/${params.id}`, {
        signal: controller.signal,
      });
      setSaccosName(response.data.data.sacco.sacco_name);
    } catch (error) {
      console.error("Error fetching saccos:", error);
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };

  useEffect(() => {
    getSaccosName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    fetchSavingsTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, currentPage, generateButtonClicked]);

  useEffect(() => {
    const pages = Math.ceil(totalDepositCount / itemsPerPage);
    setTotalPages(pages);
  }, [totalDepositCount, itemsPerPage]);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onSubmit = async (data) => {
    setGenerateButtonClicked(true);
    setIsGenerating(true);
    const formattedStartDate = format(data.start_date, "yyyy-MM-dd HH:mm:ss");
    const formattedEndDate = format(data.end_date, "yyyy-MM-dd HH:mm:ss");
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    setProgress(new ProgressBar({ size: 5, color: "#e91e63" }));
    try {
      await fetchSavingsTransactions();
    } catch (error) {
      console.error("Error fetching USSD transactions:", error);
    } finally {
      setIsGenerating(false);
      setProgress(null);
    }
  };

  // function to export data as pdf
  const downloadUssdReportAsPDF = async () => {
    try {
      setIsPDFDownloading(true);
      if (progress) {
        progress.start();
      }
      const requestData = {
        start_date: startDate,
        end_date: endDate,
      };
      const response = await axiosPrivate.post(
        `/exports/savings/${params.id}/pdf`,
        requestData,
        { responseType: "blob" }
      );
      const saccoName =
        depositTransactions.length > 0
          ? depositTransactions[0].sacco_name
          : "UnknownSacco";
      const filename = `${saccoName}_USSD_Report.pdf`;
      fileDownload(response.data, filename);
      // setIsGenerating(false); // Set isGenerating to false after download is complete
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading USSD report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsPDFDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };

  //download as csv function

  const downloadUssdReportAsCSV = async () => {
    try {
      setIsCSVDownloading(true);
      if (progress) {
        progress.start();
      }
      const requestData = {
        start_date: startDate,
        end_date: endDate,
      };
      const response = await axiosPrivate.post(
        `/exports/savings/${params.id}/csv`,
        requestData,
        { responseType: "blob" }
      );
      const saccoName =
      depositTransactions.length > 0
        ? depositTransactions[0].sacco_name
        : "UnknownSacco";
        const filename = `${saccoName}_USSD_Report.csv`;
      fileDownload(response.data, filename);
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      console.error("Error downloading USSD report:", error);
      if (!error?.response) {
        console.log("No server response");
      } else {
        console.log(error?.response);
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
      }
    } finally {
      setIsCSVDownloading(false);
      if (progress) {
        progress.finish();
      }
    }
  };

  return (
    <>
      <Toaster />
      <AdminHeader />
      <div className="d-flex align-items-stretch">
        <AdminSidebar />
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header bg-dark text-white">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        {saccosName}{" "} Savings Report
                      </h2>
                    </div>
                    <div className="card-body mt-3 mb-3">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <form
                            className="row needs-validation"
                            id="generalLegerForm"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="form-group col-md-3">
                              <label className="form-label">
                                Select Start Date
                              </label>
                              <Controller
                                name="start_date"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <DatePicker
                                    className="form-control border-0 shadow form-control-md input-text"
                                    selected={field.value}
                                    onChange={(date) => field.onChange(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm:ss"
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    timeIntervals={1}
                                    timeCaption="time"
                                  />
                                )}
                              />
                              {errors?.start_date?.type === "required" && (
                                <p className="text-small m-0 text-danger help-block">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-3">
                              <label className="form-label">
                                Select End Date
                              </label>
                              <Controller
                                name="end_date"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <DatePicker
                                    className="form-control border-0 shadow form-control-md input-text"
                                    selected={field.value}
                                    onChange={(date) => field.onChange(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm:ss"
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    timeIntervals={1}
                                    timeCaption="time"
                                  />
                                )}
                              />
                              {errors?.end_date?.type === "required" && (
                                <p className="text-small m-0 text-danger help-block">
                                  This field is required
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-3 pt-3 mt-3">
                              <button
                                type="submit"
                                className="btn btn-primary text-center rounded-0"
                                disabled={isGenerating}
                              >
                                {!isGenerating
                                  ? "Generate"
                                  : "Please Wait ....."}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <h5>Sacco Name:{saccoName} </h5>
                          </div>
                          <div>
                            <span className="mr-3">
                              Start Date: {startDate}
                            </span>
                            <span>End Date: {endDate}</span>
                          </div>
                        </div>
                        <table className="table table-hover table-bordered text-medium-small">
                          <thead>
                            <tr className="bg-dark text-white text-medium-small">
                              <th>Client</th>
                              <th>Account Number</th>
                              <th>Amount</th>
                              <th>Transaction Code</th>
                              <th>Method</th>
                              <th>Timestamp</th>
                            </tr>
                          </thead>
                          <tbody>
                            {depositTransactions.map((transaction) => (
                              <tr key={transaction.id}>
                                <td>{transaction.client}</td>
                                <td>{transaction.account_number}</td>
                                <td style={{ textAlign: "right" }}>
                                  {parseInt(
                                    transaction.amount
                                  ).toLocaleString()}
                                </td>
                                <td>{transaction.transaction_code}</td>
                                <td>{transaction.method}</td>
                                <td>{transaction.timestamp}</td>
                              </tr>
                            ))}
                            <tr className="bg-dark text-white">
                              <th colSpan="2">Total Savings Amount</th>
                              <th style={{ textAlign: "right" }}>
                                {parseInt(ussdAmount).toLocaleString()}
                              </th>
                              <th colSpan="2">Total Savings Count</th>
                              <th>{totalDepositCount.toLocaleString()}</th>
                            </tr>
                          </tbody>
                        </table>
                        <div className="text-center">
                          <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={totalDepositCount}
                            currentPage={currentPage}
                            onPageChange={onPageChange}
                            totalPages={totalPages}
                          />
                          <div className="d-flex align-items-center">
                            <div className="ml-auto">
                              <button
                                className="btn btn-primary text-right"
                                onClick={downloadUssdReportAsPDF}
                                // disabled={ussdTransactions.length === 0}
                                disabled={isGenerating || isPDFDownloading}
                              >
                                {isPDFDownloading
                                  ? "Downloading please wait..."
                                  : "Download PDF"}
                              </button>
                              <button
                                className="btn btn-secondary text-right"
                                onClick={downloadUssdReportAsCSV}
                                disabled={isGenerating || isCSVDownloading}
                              >
                                {isCSVDownloading
                                  ? "Downloading please wait..."
                                  : "Download CSV"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <AdminFooter />
    </>
  );
};

export default SaccoSavingsReport;
