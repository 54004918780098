import { useState, useEffect } from "react";
import AdminFooter from "./components/admin_footer";
import AdminSidebar from "pages/components/admin_sidebar";
import AdminHeader from "./components/admin_header";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "config/axios";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";

//import toast, { Toaster } from "react-hot-toast";
//import ProgressBar from "@badrap/bar-of-progress";
import Select from "react-select";
const FailedUssdReport = () => {
  //const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const { saccoId } = useParams();
  //const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [ussdTransactions, setUssdTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [saccos, setSaccos] = useState([]);

  const [startDate, setStartDate] = useState(""); // State to hold the selected start date
  const [endDate, setEndDate] = useState(""); // State to hold the selected end date
  const [selectedSaccoName, setSelectedSaccoName] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const [isCsvAvailable, setIsCsvAvailable] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  // const [data, setData] = useState([]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "45px", // adjust the height as needed
    }),
  };
  const controller = new AbortController();

  // Fetch available saccos
  const fetchSaccos = async () => {
    try {
      const response = await axios.get("/saccos"); // Replace with your API endpoint
      setSaccos(response.data.data.saccos);
      //console.log(response.data.data.saccos);
      //console.log(response.data.data.rows_returned);
    } catch (error) {
      console.error("Error fetching saccos:", error);
    }
  };

  useEffect(() => {
    fetchSaccos();
  }, []);
  const getUssdTransactions = async (saccoId) => {
    try {
      const response = await axios.get(`/deposit_transactions/${saccoId}`, {
        signal: controller.signal,
      });
      setUssdTransactions(response.data.data.transactions);
      //console.log(response.data.data.Transactions);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    if (saccoId) {
      getUssdTransactions(saccoId, startDate, endDate); // Use startDate and endDate from state
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saccoId, startDate, endDate]); // Make sure to include them in the dependency array

  useEffect(() => {
    // function to sort the messages in  ascending order using the time stamp
    const sortByTimestampDesc = (a, b) =>
      new Date(b.timestamp) - new Date(a.timestamp);
    // filtering the transactions based on start date and end date and method is cash
    const newFilteredUssdTransactions = ussdTransactions
      .filter((transaction) => {
        const transactionDate = new Date(transaction.timestamp);
        return (
          transactionDate >= new Date(startDate) &&
          transactionDate <= new Date(endDate) &&
          transaction.method === "ussd" && 
          transaction.status === "failed"
        );
      })
      .sort(sortByTimestampDesc);
    //console.log(newFilteredUssdTransactions);
    setFilteredTransactions(newFilteredUssdTransactions);
  }, [ussdTransactions, startDate, endDate]);

  useEffect(() => {
    reset();
    // eslint-disable-next-line
  }, []);
  //Handling the submit function
  // Calculate total amount
  const totalAmount = filteredTransactions.reduce((acc, transaction) => {
    return acc + parseFloat(transaction.amount); // Assuming 'amount' is a numeric value
  }, 0);
  const onSubmit = async ({ sacco, start_date, end_date }) => {
    setStartDate(start_date); // Update the state with selected start date
    setEndDate(end_date); // Update the state with selected end date
    const selectedSacco = saccos.find((s) => s.id === sacco.value);
    setSelectedSaccoName(selectedSacco.sacco_name);
    setIsGenerating(true);
    try {
      await getUssdTransactions(sacco.value, start_date, end_date);
      setIsReportGenerated(true);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsGenerating(false);
    }
  };
  // function to toggle csv availability based on report generation
  useEffect(() => {
    setIsCsvAvailable(isReportGenerated);
  }, [isReportGenerated]);

  // Function to download the report as CSV
  const downloadReportAsCSV = () => {
    // Example: Generate CSV data and initiate download
    if (isCsvAvailable) {
      let csvData =
        "Client,Account Number, Amount,Transaction Code,Timestamp\n";
      filteredTransactions.forEach((transaction) => {
        let formatedAmount = transaction.amount.toLocaleString('en-US');
        csvData += `${transaction.client},${transaction.account_number},${formatedAmount},${transaction.transaction_code},${transaction.timestamp}\n`;
      });
      // Add the Total Amount row to the CSV data
      csvData += `Total Amount,${totalAmount}`;
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${selectedSaccoName}_ussd_report.csv`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };
  //Downloading the report as a pdf
  const downloadReportAsPDF = () => {
    if (isReportGenerated) {
      try {
        const doc = new jsPDF("p", "mm", "a4");

        const pageWidth = doc.internal.pageSize.getWidth();
        doc.setFontSize(12);
        doc.text("USSD Report", pageWidth / 2, 10, { align: "center" });
        doc.setFontSize(10);
        doc.text("Sacco Name: " + selectedSaccoName, 10, 10, { align: "left" });
        doc.text("Start Date: " + startDate, pageWidth - 10, 10, {
          align: "right",
        });
        doc.text("End Date: " + endDate, pageWidth - 10, 20, {
          align: "right",
        });

        const columns = [
          { header: "Client", dataKey: "client" },
          { header: "Amount", dataKey: "amount" },
          { header: "Account No", dataKey: "account_number" },
          { header: "Transaction Code", dataKey: "transaction_code" },
          { header: "Timestamp", dataKey: "timestamp" },
        ];

        const rows = filteredTransactions.map((transaction) => {
          return {
            client: transaction.client,
            account_number: transaction.account_number,
            amount: formatAmount(transaction.amount),
            transaction_code: transaction.transaction_code,
            timestamp: transaction.timestamp,
          };
        });

        // Adding the totalAmount row to the rows
        rows.push({
          client: "Total Amount",
          account_number: "",
          amount: formatAmount(totalAmount),
          transaction_code: "",
          timestamp: "",
        });

        // Adding the total ussd count row to the rows
        rows.push({
          client: "Ussd Count",
          amount: filteredTransactions.length,
          transaction_code: "",
          account_number: "",
          timestamp: "",
        });

        doc.autoTable({
          columns: columns,
          body: rows,
          startY: 30, // adjust this value based on your text's y-coordinate
          styles: { fontSize: 10 },
          columnStyles: { amount: { halign: 'right' } }, 
          didDrawPage: function (data) {
            // Add page number to each page
            doc.setFontSize(10);
            var pageNumber = doc.internal.getNumberOfPages();
            var pageCenter = doc.internal.pageSize.width / 2; // Calculate the center of the page
            doc.text("Page " + pageNumber, pageCenter, doc.internal.pageSize.height - 10, { align: "center" });
          },
          didParseCell: function (data) {
            const rows = data.table.body;
            if (data.row.index === rows.length - 1) {
              // Add border to the last row
              data.cell.styles.lineWidth = 0.1;
              data.cell.styles.lineColor = [0, 0, 0];
            } else {
              // Add borders to other cells
              data.cell.styles.lineWidth = 0.1;
              data.cell.styles.lineColor = [0, 0, 0];
              data.cell.styles.borders = "LTRB"; // All borders: left, top, right, bottom
            }
          },
        });

        doc.save(`${selectedSaccoName}_ussd_report.pdf`);
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    }
  };
  // function to format amount
  const formatAmount = (number) => {
    // Convert to a number if not already a number
    const num = Number(number);
    // Check if num is a number
    if (isNaN(num)) {
      throw new Error(`${number} is not a number`);
    }
    return num.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,");
  };

  return (
    <>
      {/* <Toaster /> */}
      {/* .............header...................... */}
      <AdminHeader />
      {/* .............header end..................... */}

      <div className="d-flex align-items-stretch">
        {/* .............siderbar...................... */}
        <AdminSidebar />
        {/* ..............sidebar end..................... */}
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        USSD Report{" "}
                      </h2>
                    </div>
                    <div className="card-body mt-3 mb-3">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <form
                            className="row needs-validation"
                            id="generalLegerForm"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="form-group col-md-3">
                              <label className="form-label">Select sacco</label>
                              <Controller
                                control={control}
                                name="sacco"
                                render={({
                                  field: { onChange, onBlur, value, name, ref },
                                }) => (
                                  <Select
                                    {...register("sacco", {
                                      required: true,
                                    })}
                                    options={saccos.map((sacco) => ({
                                      value: sacco.id,
                                      label: sacco.sacco_name, // Adjust to match your API response structure
                                    }))}
                                    isMulti={false}
                                    className="shadow border-0"
                                    styles={customStyles}
                                    name={name}
                                    value={value}
                                    onBlur={onBlur}
                                    ref={ref}
                                    onChange={(selectedOption) => {
                                      onChange(selectedOption);
                                    }}
                                  />
                                )}
                              />
                              {errors?.sacco?.type === "required" && (
                                <p className="text-small m-0 text-start text-danger help-block">
                                  Please select sacco
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-3">
                              <label className="form-label">
                                Select Start Date
                              </label>
                              <input
                                type="date"
                                placeholder="Select Start Date"
                                className="form-control form-control-md input-text shadow border-0"
                                {...register("start_date", { required: true })}
                              />
                              {errors?.start_date?.type === "required" && (
                                <p className="text-small m-0 text-danger help-block">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-3">
                              <label className="form-label">
                                Select End Date
                              </label>
                              <input
                                type="date"
                                placeholder="Select End Date"
                                className="form-control form-control-md input-text shadow border-0"
                                {...register("end_date", { required: true })}
                              />
                              {errors?.end_date?.type === "required" && (
                                <p className="text-small m-0 text-danger help-block">
                                  This field is required
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-3 pt-3 mt-3">
                              <button
                                type="submit"
                                className="btn btn-primary text-center rounded-0"
                                disabled={isGenerating} // disable the button while generating
                              >
                                {!isGenerating
                                  ? "Generate"
                                  : "Please Wait ....."}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className="d-flex justify-content-between mb-3">
                          <div>
                            <h5>Sacco Name: {selectedSaccoName}</h5>
                          </div>
                          <div>
                            <span className="mr-3">
                              Start Date: {startDate}
                            </span>
                            <span>End Date: {endDate}</span>
                          </div>
                        </div>
                        {/* Table to display messages */}
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>Client</th>
                              <th>Account Number</th>
                              <th>Amount</th>
                              <th>Transaction Code</th>
                              <th>Timestamp</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredTransactions.map((transaction, index) => (
                              <tr key={index}>
                                <td>{transaction.client}</td>
                                <th>{transaction.account_number}</th>
                                <td>
                                  {formatAmount(parseFloat(transaction.amount))}
                                </td>
                                <td>{transaction.transaction_code}</td>
                                <td>{transaction.timestamp}</td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            {isReportGenerated && (
                              <>
                                <tr>
                                  <td colSpan="4">Total Amount:</td>
                                  <td className="font-weight-bold">
                                    {formatAmount(totalAmount)}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="4">Total USSD Count:</td>
                                  <td className="font-weight-bold">
                                    {filteredTransactions.length}
                                  </td>
                                </tr>
                              </>
                            )}
                          </tfoot>
                        </table>
                        <div className="d-flex align-items-center">
                          <div className="ml-auto">
                            <button
                              className="btn btn-primary"
                              onClick={downloadReportAsPDF}
                              disabled={!isReportGenerated}
                            >
                              Download pdf
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={downloadReportAsCSV}
                              disabled={!isCsvAvailable}
                            >
                              Download CSV
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          {/* footer............................ */}
          <AdminFooter />
          {/* footer end............................ */}
        </div>
      </div>
    </>
  );
};

export default FailedUssdReport;
