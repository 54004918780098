import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import AdminFooter from "./components/admin_footer";
import AdminSidebar from "../pages/components/admin_sidebar";
import AdminHeader from "./components/admin_header";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../middlewares/hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";

import "jspdf-autotable";
import LiqiudateUssdModal from "./modal/liquidate_ussd";
import UssdLiquidationTable from "./liquidation_table";
const LiquidationHistory = () => {
  //const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const [isLoading, setIsLoading] = useState(true);
  // const [liquidationData, setLiquidationData] = useState([]);
  const [saccoLiquidationHistoryData, setSaccoLiquidationHistoryData] =
    useState([]);
  const [saccosName, setSaccosName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const getSaccoLiquidationHistory = async () => {
    try {
      const response = await axiosPrivate.get(
        `/liquidation_history/${params.id}`,
        {
          signal: controller.signal,
        }
      );
      console.log(response);
      setSaccoLiquidationHistoryData(response.data.data.liquidation_history);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching sacco history:", error);
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    } finally {
      setIsLoading(false); // Ensure setIsLoading(false) is called here
    }
  };

  // const getTotalUssdDepositInTimestamp = async () => {
  //   try {
  //     const response = await axiosPrivate.get(`/total_ussd_deposit/${params.id}`);
  //     setTotalUssdDepositInTimestamp(response.data.totalUssdDepositInTimestamp);
  //   } catch (error) {
  //     console.error("Error fetching total USSD deposit:", error);
  //   }
  // };
  const getSaccosName = async () => {
    try {
      const response = await axiosPrivate.get(`/saccos/${params.id}`, {
        signal: controller.signal,
      });
      setSaccosName(response.data.data.sacco.sacco_name);
    } catch (error) {
      console.error("Error fetching saccos:", error);
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };

  useEffect(() => {
    getSaccosName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSaccoLiquidationHistory();

    // console.log(saccoLiquidationHistoryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return (
    <>
      <AdminHeader />
      <div className="d-flex align-items-stretch">
        <AdminSidebar />
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        {saccosName} liquidation history{" "}
                      </h2>
                      <button
                        className="btn btn-outline-success float-right"
                        onClick={openModal}
                      >
                        Liquidate sacco
                      </button>
                      <LiqiudateUssdModal
                        showModal={isModalOpen}
                        onClose={closeModal}
                        getSaccoLiquidationHistory={getSaccoLiquidationHistory}
                      />
                    </div>
                    <div className="card-body mt-3 mb-3">
                      <div className="mt-3">
                        {isLoading ? (
                          <div>Loading...</div>
                        ) : saccoLiquidationHistoryData.length > 0 ? (
                          <UssdLiquidationTable
                            saccoLiquidationHistoryData={
                              saccoLiquidationHistoryData
                            }
                          />
                        ) : (
                          <div>No Liquidation history for this sacco</div>
                        )}
                        <div className="d-flex align-items-center justify-content-between mt-3">
                          <div className="d-flex align-items-center">
                            <Button
                              className="btn btn-primary"
                              // onClick={handlePreviousPage}
                              // disabled={currentPage === 1}
                            >
                              Previous
                            </Button>
                            <span className="mx-2">
                              {/* Page {currentPage} of {totalPages} */}
                            </span>
                            <Button
                              className="btn btn-primary"
                              // onClick={handleNextPage}
                              // disabled={currentPage === totalPages}
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="ml-auto">
                            <Button
                              className="btn btn-primary"
                              // onClick={() => downloadReportAsPDF(allSmsData)}
                              // onClick={handleDownloadButtonClick}
                              // disabled={!isReportGenerated}
                            >
                              Download
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <AdminFooter />
        </div>
      </div>
    </>
  );
};

export default LiquidationHistory;
