import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../../middlewares/hooks/useAuth";

const PersitLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (err) {
                console.error("Failed to refresh token:", err);
                // Handle the error appropriately, e.g., redirect to login page
            } finally {
                setIsLoading(false);
            }
        };
        if (!auth?.accesstoken) {
            verifyRefreshToken();
        } else {
            setIsLoading(false);
        }
    }, [auth, isLoading, refresh]); // Removed isLoading from the dependency array

    return (
        <>
             <Outlet />
        </>
    );
};

export default PersitLogin;
