import { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import AdminFooter from "./components/admin_footer";
import AdminSidebar from "../pages/components/admin_sidebar";
import AdminHeader from "./components/admin_header";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../middlewares/hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import "jspdf-autotable";
import toast, { Toaster } from "react-hot-toast";
import Loader from "services/loaders/loader";
import SmsReportTable from "./sms-table";
import "jspdf-autotable";
import WithdrawsReportTable from "./withdraws_table";
const WithdrawsReport = () => {
  //const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  //const progress = new ProgressBar({ size: 5, color: "#e91e63" });

  const [startDate, setStartDate] = useState(""); // State to hold the selected start date
  const [endDate, setEndDate] = useState(""); // State to hold the selected end date
  const [isGenerating, setIsGenerating] = useState(false);
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [saccoName, setSaccoName] = useState([]);
  const [smsCount, setSmsCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [allSmsData, setAllSmsData] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm();
  //const messagesRef = useRef([null]);
  const [withdraws, setWithdraws] = useState([]); // State to hold the table data
  const messagesRef = useRef([]);
  // const [data, setData] = useState([]);
  const controller = new AbortController();
  //const [messages, setMessages] = useState([]);

  const [loaderVisible, setLoaderVisible] = useState(false);
  const pageSize = 10; // Set your desired page size
  const fetchDataForPage = async (startDate, endDate, pageNumber) => {
    try {
      progress.start();
      setLoaderVisible(true);

      const requestData = {
        start_date: startDate,
        end_date: endDate,
      };

      const response = await axiosPrivate.post(
        `/sms_report/${params.id}/page/${pageNumber}`,
        requestData,
        {
          signal: controller.signal,
        }
      );

      const responseData = response.data.data;
      console.log(responseData);
      const { sms_count, sms, total_amount, sacco } = responseData;
      console.log(sms);
      console.log("sms count", sms_count);
      console.log("total amount", total_amount);
      console.log("sacco name", sacco);
      //console.log(responseData);
      setTotalPages(Math.ceil(sms_count / pageSize));
      if (pageNumber === 1) {
        messagesRef.current = sms;
      } else if (messagesRef.current) {
        // Only update messagesRef.current if it's already initialized
        messagesRef.current = [...messagesRef.current, ...sms];
      }
      //setSms(sms);
      setCurrentPage(pageNumber);
      setDataLoaded(true);
      //setAllSmsData((prevData) => [...prevData, ...sms]);
      setSaccoName(sacco);
      setSmsCount(sms_count); // Update smsCount state
      setTotalAmount(total_amount); // Update totalAmount state
      progress.finish();
      setDataLoaded(true);
      reset();
    } catch (error) {
      console.log(error);
      if (!error?.response) {
        toast.error("No server response");
        progress.finish();
      } else {
        console.log(error?.response);
        progress.finish();
        if (error?.response?.status === 401) {
          navigate("/", { state: { from: location }, replace: true });
        }
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
    }
  };

  // Function to fetch data for all pages and accumulate it

  // Define handlePreviousPage function
  const handlePreviousPage = async () => {
    // Calculate the previous page number
    const prevPage = currentPage - 1;
    // Check if the previous page is within the valid range
    if (prevPage >= 1) {
      // Only update the currentPage state before fetching the data
      setCurrentPage(prevPage);

      // Fetch the data for the previous page
      await fetchDataForPage(startDate, endDate, prevPage);
      setWithdraws([...visibleMessages]);
    }
  };
  const handleNextPage = async () => {
    // Calculate the next page number
    const nextPage = currentPage + 1;
    if (nextPage <= totalPages) {
      setCurrentPage(nextPage);
      await fetchDataForPage(startDate, endDate, nextPage);
      setWithdraws([...visibleMessages]);
    }
  };
  const visibleMessages = messagesRef.current.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  //console.log("Messages:", visibleMessages);
  //console.log("Current Page:", currentPage);
  //console.log("Total Pages:", totalPages);

  useEffect(
    () => {
      if (startDate && endDate && isReportGenerated && !dataLoaded) {
        fetchDataForPage(startDate, endDate, currentPage);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate, isReportGenerated, dataLoaded, currentPage]
  );

  const onSubmit = async ({ start_date, end_date }) => {
    setIsGenerating(true);
    setStartDate(start_date); // Update the state with the selected start date
    setEndDate(end_date); // Update the state with the selected end date
    setLoaderVisible(true);
    try {
      // Assuming the initial page is 1
      await fetchDataForPage(start_date, end_date, 1);
      setWithdraws([...messagesRef.current]);
      setIsReportGenerated(true);
      navigate(`/sacco/savings/savings_report/${parseInt(params.id)}`);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsGenerating(false);
    }
  };
  const isFormValid = isValid && !isGenerating;
async function fetchAllData() {
  try {
    const requestData = {
      start_date: startDate,
      end_date: endDate,
    };

    let allSms = [];
    let totalPages = 1; // Assuming at least 1 page is available initially
    let currentPage = 1;
    // Fetch each page until all pages are fetched
    while (currentPage <= totalPages) {
      const response = await axiosPrivate.post(
        `/sms_report/${params.id}/page/${currentPage}`,
        requestData,
        {
          signal: controller.signal,
        }
      );

      const responseData = response.data;
      // Extract sms from the page data and concatenate to allSms array
      allSms = allSms.concat(responseData.data.sms);

      // Update totalPages if available in the response
      if (responseData.data.total_pages) {
        totalPages = responseData.data.total_pages;
      }
      // Move to the next page
      currentPage++;
    }
    return allSms; // Return the accumulated SMS data
  } catch (error) {
    console.error("Error fetching all data:", error);
    throw error; // Rethrow the error for handling elsewhere if needed
  }
}
  return (
    <>
      <Toaster />
      <AdminHeader />
      <div className="d-flex align-items-stretch">
        <AdminSidebar />
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        Withdraws Report{" "}
                      </h2>
                    </div>
                    <div className="card-body mt-3 mb-3">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <form
                            className="row needs-validation"
                            id="generalLegerForm"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="form-group col-md-3">
                              <label className="form-label">
                                Select Start Date
                              </label>
                              <input
                                type="date"
                                placeholder="Select Start Date"
                                className="form-control form-control-md input-text shadow border-0"
                                {...register("start_date", { required: true })}
                              />
                              {errors?.start_date?.type === "required" && (
                                <p className="text-small m-0 text-danger help-block">
                                  This field is required
                                </p>
                              )}
                            </div>
                            <div className="form-group col-md-3">
                              <label className="form-label">
                                Select End Date
                              </label>
                              <input
                                type="date"
                                placeholder="Select End Date"
                                className="form-control form-control-md input-text shadow border-0"
                                {...register("end_date", { required: true })}
                              />
                              {errors?.end_date?.type === "required" && (
                                <p className="text-small m-0 text-danger help-block">
                                  This field is required
                                </p>
                              )}
                            </div>

                            <div className="form-group col-md-3 pt-3 mt-3">
                              <Button
                                type="submit"
                                className="btn btn-primary text-center rounded-0"
                                disabled={!isFormValid} // disable the button while generating
                              >
                                {!isGenerating
                                  ? "Generate"
                                  : "Please Wait ....."}
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="mt-3">
                        {loaderVisible && !dataLoaded ? (
                          <Loader />
                        ) : (
                          <div className="d-flex justify-content-between mb-3">
                            <div>
                              <h5>Sacco Name: {saccoName}</h5>
                            </div>
                            <div>
                              <span className="mr-3">
                                Start Date: {startDate}
                              </span>
                              <span>End Date: {endDate}</span>
                            </div>
                          </div>
                        )}
                        <WithdrawsReportTable withdraws={withdraws} />
                        <div className="d-flex align-items-center justify-content-between mt-3">
                          <div className="d-flex align-items-center">
                            <Button
                              className="btn btn-primary"
                              onClick={handlePreviousPage}
                              disabled={currentPage === 1}
                            >
                              Previous
                            </Button>
                            <span className="mx-2">
                              Page {currentPage} of {totalPages}
                            </span>
                            <Button
                              className="btn btn-primary"
                              onClick={handleNextPage}
                              disabled={currentPage === totalPages}
                            >
                              Next
                            </Button>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="mr-3">Total SMS: {smsCount}</span>
                            <span>Total Amount: {totalAmount}</span>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="ml-auto">
                            <Button
                              className="btn btn-primary"
                              // onClick={() => downloadReportAsPDF(allSmsData)}
                              // onClick={handleDownloadButtonClick}
                              disabled={!isReportGenerated}
                            >
                              Download Report
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <AdminFooter />
        </div>
      </div>
    </>
  );
};

export default WithdrawsReport;
