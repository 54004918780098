/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from "react";
import AdminFooter from "./components/admin_footer";
import AdminHeader from "./components/admin_header";
import AdminSidebar from "./components/admin_sidebar";
import MaterialReactTable from "material-react-table";
import useAxiosPrivate from "../middlewares/hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";

const SaccoView = () => {
  const [members, setMembers] = useState([]);
  const [smsCount, setSmsCount] = useState([]);
  const [allSmsCount, setAllSmsCount] = useState([]);
  const [paidSmsCount, setPaidSmsCount] = useState([]);
  const [smsAmount, setSmsAmount] = useState([]);
  const [AllSmsAmount, setAllSmsAmount] = useState([]);
  const [smsIncome, setSmsIncome] = useState([]);
  const [smsAvailableIncome, setSmsAvailableIncome] = useState([]);
  const [paidSmsAmount, setPaidSmsAmount] = useState([]);
  const [pendingUssd, setPendingUssd] = useState([]);
  const [liquidatedUssd, setLiquidatedUssd] = useState([]);
  const [isLoadingSmsCount, setIsLoadingSmsCount] = useState(true);
  const [isLoadingAllSmsCount, setIsLoadingAllSmsCount] = useState(true);
  const [isLoadingSmsAmount, setIsLoadingSmsAmount] = useState(true);
  const [isLoadingAllSmsAmount, setIsLoadingAllSmsAmount] = useState(true);
  const [isLoadingPaidSmsCount, setIsLoadingPaidSmsCount] = useState(true);
  const [isLoadingPaidSmsAmount, setIsLoadingPaidSmsAmount] = useState(true);
  const [isLoadingSmsIncome, setIsLoadingSmsIncome] = useState(true);
  const [isLoadingSmsAvailableIncome, setIsLoadingSmsAvailableIncome] = useState(true);
  const [isLoadingPendingUssd, setIsLoadingPendingUssd] = useState(true);
  const [isLoadingLiquidatedUssd, setIsLoadingLiquidatedUssd] = useState(true);
  const [isLoadingWithdraws, setIsLoadingWithdraws] = useState(true);

  const [withdraws, setWithdraws] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [branches, setBranches] = useState([]);
  const [saccoUsers, setSaccoUsers] = useState([]);
  const [saccoLoanApplications, setSaccoLoanApplications] = useState([]);
  const [saccoLoanProducts, setSaccoLoanProducts] = useState([]);
  const [saccoBranches, setsaccoBranches] = useState([]);
  const [saccoWithdrawSettings, setsaccoWithdrawSettings] = useState([]);

  // const [saccoWithdrawSettings, setsaccoWithdrawSettings] = useState([]);
  // const [saccoUserss, setsaccoUserss] = useState([]);
  const [saccoClients, setSaccoClients] = useState([]);
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const controller = new AbortController();
  const [isLoading, setIsLoading] = useState(true);
// get sacco members
  const getMembers = async () => {
    try {
      const response = await axiosPrivate.get(`/sacco_clients/${params.id}`);
      setMembers(response.data.data.rows_returned);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const getSms = async () => {
    try {
      const response = await axiosPrivate.get(`/sms/${params.id}`, {
        signal: controller.signal,
      });
      setSmsCount(response?.data?.data?.sacco_sms_count);
      console.log(response?.data?.data?.sacco_sms_count);
      setSmsAmount(response?.data?.data?.sacco_sms_amount);
      setIsLoadingSmsCount(false);
      setIsLoadingSmsAmount(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  // get all sent messages wether paid or not
  const getAllSms = async () => {
    try {
      const response = await axiosPrivate.get(`/all_sent_sms/${params.id}`, {
        signal: controller.signal,
      });
      setAllSmsCount(response?.data?.data?.sacco_sms_count);
      console.log(response?.data?.data?.sacco_sms_count);
      setAllSmsAmount(response?.data?.data?.sacco_sms_amount);
      setIsLoadingAllSmsCount(false);
      setIsLoadingAllSmsAmount(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  // get paid sms count and amount
  const getPaidSms = async () => {
    try {
      const response = await axiosPrivate.get(`/paid_sms/${params.id}`, {
        signal: controller.signal,
      });
      const count = response?.data?.data?.sacco_sms_count || "0";
      const amount = response?.data?.data?.sacco_sms_amount || "Ugx 0";
      setPaidSmsCount(count);
      setPaidSmsAmount(amount); // Setting formatted amount string
      setIsLoadingPaidSmsCount(false);
      setIsLoadingPaidSmsAmount(false);
    } catch (error) {
      setIsLoadingPaidSmsCount(false);
      setIsLoadingPaidSmsAmount(false);
      setPaidSmsCount(0);
      setPaidSmsAmount("Ugx 0"); // Setting default amount string
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  // get actual sacco sms income 
  const getActualSmsIncome = async () => {
    try {
      const response = await axiosPrivate.get(`/sacco_sms_income/${params.id}`, {
        signal: controller.signal,
      });
      const amount = response?.data?.data?.sacco_sms_income || "Ugx 0";
      // setPaidSmsCount(count);
      setSmsIncome(amount); // Setting formatted amount string
      setIsLoadingSmsIncome(false);
    } catch (error) {
      setIsLoadingSmsIncome(false);
      setSmsIncome("Ugx 0"); // Setting default amount string
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  // get available sms income from paid sms
  const getAvailableSmsIncome = async () => {
    try {
      const response = await axiosPrivate.get(`/available_sms_income/${params.id}`, {
        signal: controller.signal,
      });
      const amount = response?.data?.data?.sacco_sms_income || "Ugx 0";
      // setPaidSmsCount(count);
      setSmsAvailableIncome(amount); // Setting formatted amount string
      setIsLoadingSmsAvailableIncome(false);
    } catch (error) {
      setIsLoadingSmsAvailableIncome(false);
      setSmsAvailableIncome("Ugx 0"); // Setting default amount string
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  // get pending ussd amount for each sacco 
  const getPendingUssd = async () => {
    try {
      const response = await axiosPrivate.get(`/pending/${params.id}`, {
        signal: controller.signal,
      });
      const amount = response?.data?.data?.ussd_amount || "Ugx 0";
      setPendingUssd(amount); // Setting formatted amount string
      setIsLoadingPendingUssd(false);
    } catch (error) {
      setIsLoadingPendingUssd(false);
      setPendingUssd("Ugx 0"); // Setting default amount string
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  // get liquidated ussd amount for each sacco 
  const getLiquidatedUssd = async () => {
    try {
      const response = await axiosPrivate.get(`/liquidated/${params.id}`, {
        signal: controller.signal,
      });
      const amount = response?.data?.data?.ussd_amount || "Ugx 0";
      setLiquidatedUssd(amount); // Setting formatted amount string
      setIsLoadingLiquidatedUssd(false);
    } catch (error) {
      setIsLoadingLiquidatedUssd(false);
      setLiquidatedUssd("Ugx 0"); // Setting default amount string
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  // get withdraw transactions for the sacco 
  const getSaccoWithdraws = async () => {
    try {
      const response = await axiosPrivate.get(`/withdraw_transactions/${params.id}`, {
        signal: controller.signal,
      });
      const amount = response?.data?.data?.withdraw_amount || "Ugx 0";
      setWithdraws(amount); // Setting formatted amount string
      setIsLoadingWithdraws(false);
    } catch (error) {
      setIsLoadingWithdraws(false);
      setWithdraws("Ugx 0"); // Setting default amount string
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  const getSaccoDeposits = async () => {
    try {
      const response = await axiosPrivate.get(`/deposit_transactions/${params.id}`, {
        signal: controller.signal,
      });
      const amount = response?.data?.data?.deposit_amount || "Ugx 0";
      setDeposits(amount); // Setting formatted amount string
     
    } catch (error) {
      setDeposits("Ugx 0"); // Setting default amount string
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
// getting sacco clients 

const getSaccoClients = async () => {
    try {
      const response = await axiosPrivate.get(`/sacco_clients/${params.id}`, {
        signal: controller.signal,
      });
      setSaccoClients(response.data.data.clients);
      console.log(response.data.data.clients);
      setIsLoading(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };

  const getSaccoWithdrawSettings = async () => {
    try {
      const response = await axiosPrivate.get(
        `/withdraw_settings/${params.id}`
      );
      setsaccoWithdrawSettings(response.data.data.withdrawal_settings);
    } catch (error) {
      console.error("Error:", error);
    }
  };
//get sacco branches
    const getSaccoBranches = async () => {
    try {
      const response = await axiosPrivate.get(`/sacco_branches/${params.id}`);
      setsaccoBranches(response.data.data.branches);
    } catch (error) {
      console.error("Error:", error);
    }
  };
//get branches 
  const getBranches = async () => {
    try {
      const response = await axiosPrivate.get(`/sacco_branches/${params.id}`);
      setBranches(response.data.data.rows_returned);
    } catch (error) {
      console.error("Error:", error);
    }
  }
// sacco users 
  const getSaccousers = async () => {
    try {
      const response = await axiosPrivate.get(`/sacco_users/${params.id}`);
      setSaccoUsers(response.data.data.rows_returned);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // loan applications 
  const getSaccoLoanApplications = async () => {
    try {
      const response = await axiosPrivate.get(
        `/loan_applications/${params.id}`
      );
      setSaccoLoanApplications(response.data.data.rows_returned);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // sacco loan products
  const getSaccoLoanProducts = async () => {
    try {
      const response = await axiosPrivate.get(`/loan_products/${params.id}`);
      setSaccoLoanProducts(response.data.data.rows_returned);
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const columns1 = useMemo(() => [
    {
      id: "firstname", //access nested data with dot notation
      enableClickToCopy: true,
      header: "First Name",
      accessorFn: (row) => row.firstname,
      size: 50,
    },
    {
      id: "lastname", //access nested data with dot notation
      enableClickToCopy: true,
      header: "Last Name ",
      accessorFn: (row) => row.lastname,
      size: 50,
    },
    {
      id: "contact", //access nested data with dot notation
      enableClickToCopy: true,
      header: "Contact",
      accessorFn: (row) => row.contact,
      size: 50,
    },

    {
      id: "date_of_joining", //access nested data with dot notation
      enableClickToCopy: true,
      header: "Reg. Date",
      accessorFn: (row) => row.date_of_joining,
      size: 50,
    },
    {
      id: "identification", //access nested data with dot notation
      enableClickToCopy: true,
      header: "Identification",
      accessorFn: (row) => row.identification,
      size: 50,
    },
    {
      id: "client_type", //access nested data with dot notation
      enableClickToCopy: true,
      header: "Client Type",
      accessorFn: (row) => row.client_type,
      size: 50,
    },
    {
      id: "adress", //access nested data with dot notation
      enableClickToCopy: true,
      header: "Address",
      accessorFn: (row) => row.adress,
      size: 50,
    },
    {
      id: "employment_staus", //access nested data with dot notation
      enableClickToCopy: true,
      header: "Employment Status",
      accessorFn: (row) => row.employment_staus,
      size: 50,
    },
    {
      id: "malital_status", //access nested data with dot notation
      enableClickToCopy: true,
      header: "Marital Status",
      accessorFn: (row) => row.malital_status,
      size: 50,
    },
    {
      id: "status", //access nested data with dot notation
      enableClickToCopy: true,
      header: "Status",
      accessorFn: (row) => row.status,
      size: 50,
    },
  ]);

  const refetch = async () => {
    setIsLoading(true);
     await getSaccoClients();
  };

  const headers = [
    { label: "First Name", key: "sacco_name" },
    { label: "Last Name", key: "contact" },
    { label: "Contact", key: "emails" },
    { label: "Reg. Date", key: "short_name" },
    { label: "Identification", key: "sacco_location" },
    { label: "Client Type", key: "sacco_contacts" },
    { label: "Address", key: "sacco_code" },
    { label: "Employment Status", key: "sacco_status" },
    { label: "Maarital Status", key: "reg_date" },
    { label: "Status", key: "timestamp" },
  ];
  
  useEffect(() => {
    getAllSms();
    getSms();
    getPaidSms();
    getActualSmsIncome();
    getAvailableSmsIncome();
    getPendingUssd();
    getLiquidatedUssd();
    getMembers();
    getSaccoWithdraws();
    getSaccoDeposits();
    getSaccoClients();
    getSaccoWithdrawSettings();
    getSaccoBranches();
    getBranches();
    getSaccousers();
    getSaccoLoanApplications();
    getSaccoLoanProducts();
  }, []);



  return (
    <>
      {/* .............header...................... */}
      <AdminHeader />
      {/* .............header end..................... */}
      <div className="d-flex align-items-stretch">
        {/* .............siderbar...................... */}
        <AdminSidebar />
        {/* ..............sidebar end..................... */}
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5">
              <div className="row">
                <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card bg-hover-gradient-success  bg-hover-success bg-gradient-success card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            Total SMS SENT
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingAllSmsCount ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            ) : (
                              <span>{allSmsCount}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card bg-hover-gradient-success  bg-hover-success bg-gradient-success card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            Total SMS AMOUNT
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingAllSmsAmount ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            ) : (
                              <span>{AllSmsAmount}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card bg-hover-gradient-success  bg-hover-success bg-gradient-success card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            Un paid SMS 
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingSmsCount ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            ) : (
                              <span>{smsCount}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card bg-hover-gradient-success  bg-hover-success bg-gradient-success card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            Unpaid SMS Amount
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingSmsAmount ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            ) : (
                              <span>{smsAmount}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-uppercase text-white mb-1">
                            Paid SMS Count 
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingPaidSmsCount ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            ) : (
                              <span>{paidSmsCount}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-uppercase text-white mb-1">
                            Paid SMS Amount 
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingPaidSmsAmount ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            ) : (
                              <span>{paidSmsAmount}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-uppercase text-white mb-1">
                            Actual SMS income
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingSmsIncome ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            ) : (
                              <span>{smsIncome}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-uppercase text-white mb-1">
                            Available SMS income
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingSmsAvailableIncome ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            ) : (
                              <span>{smsAvailableIncome}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card card bg-hover-gradient-warning  bg-hover-warning bg-gradient-warning card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            Pending USSD
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingPendingUssd ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            ) : (
                              <span>{pendingUssd}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card bg-hover-gradient-info  bg-hover-primary bg-gradient-primary card-new border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            Liquidated USSD
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingLiquidatedUssd ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            ) : (
                              <span>{liquidatedUssd}</span>
                            )}
                          </div>
                          {/* <Link to={`/sacco/smsusage/${params.id}`}>
                            View SMS Report
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card bg-hover-gradient-success  bg-hover-success bg-gradient-success card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            Daily SMS
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            <span>UGX. {totalUssdIncome}</span>
                          </div>
                          <Link to={`/sacco/daily_sms/${params.id}`}>
                            View Daily SMS
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-uppercase text-white mb-1">
                            Daily Savings
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            <span>
                              UGX.{totalSmsIncome.toLocaleString("en-US")}
                            </span>
                          </div>
                          <Link to={`/sacco/daily_saving/${params.id}`}>
                            View Daily Savings
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card card bg-hover-gradient-warning  bg-hover-warning bg-gradient-warning card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            Daily Ussd Savings
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            <span>UGX.{calculateTotalUssdSavings()}</span>
                          </div>
                          <Link  to={`/sacco/daily_saving/${params.id}`}>View daily USSD</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-xl-3 col-md-6 mb-4 block_deposits">
                  <div className="card bg-hover-gradient-info  bg-hover-primary bg-gradient-primary card-new border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            Daily Withdraws
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            <span>UGX. {totalUssdIncome}</span>
                          </div>
                          <Link to={`/sacco/daily_withdraw/${params.id}`}>
                            View Daily  Withdraws
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="col-xl-4 col-md-6 mb-4 block_deposits">
                  <div className="card card-new border border-success border-3 shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                            sacco members
                          </div>
                          <div className="h5 mb-0 font-weight-bold">
                            <span>{members}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-4 col-md-6 mb-4 block_deposits">
                  <div className="card card-new border border-danger border-3 border-left-danger shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                            total withdraws
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-black">
                            {isLoadingWithdraws ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                            ) : (
                              <span>{withdraws}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-md-4 mb-4 block_deposits">
                  <div className="card card-new border border-info border-3 border-left-info shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                            total deposits
                          </div>
                          <div className="h5 mb-0 font-weight-bold">
                            <span>{deposits}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-4">
                  <div className="card font-weight-bold text-white bg-gradient-primary text-uppercase">
                    <div className="card-body">
                      total branches : <span>{branches}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-4">
                  <div className="card font-weight-bold text-white bg-gradient-warning text-uppercase">
                    <div className="card-body">
                      sacco users : <span>{saccoUsers}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mt-4">
                  <div className="card font-weight-bold text-white bg-gradient-danger text-uppercase">
                    <div className="card-body">
                      sacco Incomes :{" "}
                      {/* <span>UGX.{calculateTotalSaccoIncome()}</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                {/* <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header text-uppercase">
                      Deposits vs withdraws
                    </div>
                    <div className="card-body">
                      <canvas
                        role="img"
                        height="194"
                        width="388"
                        className="px-0"
                        style={{
                          display: "block",
                          boxSizing: "border-box",
                          height: "162px",
                          width: "388px",
                        }}
                      ></canvas>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-lg-3 mb-4 mb-lg-0 pl-lg-0">
                  <div className="mb-4 card font-weight-bold text-white bg-gradient-primary text-uppercase">
                    <div className="card-body">
                      total branches : <span>{branches}</span>
                    </div>
                  </div>
                  <div className="card font-weight-bold mb-4  text-white bg-gradient-warning text-uppercase">
                    <div className="card-body">
                      sacco users : <span>{saccoUsers}</span>
                    </div>
                  </div>
                  <div className="card font-weight-bold mb-4  text-white bg-gradient-danger text-uppercase">
                    <div className="card-body">
                      sacco Incomes :{" "}
                      <span>UGX.{calculateTotalSaccoIncome()}</span>
                    </div>
                  </div>
                  <div className="text-xs font-weight-bold text-white text-uppercase mb-0">
                    Sacco Income : UGX.{" "}
                    {isLoadingIncomes ? (
                      <Loader />
                    ) : (
                      saccoIncomes
                        ?.map((incomes) => incomes?.amount)
                        .reduce((a, b) => a + b, 0)
                        ?.toLocaleString()
                    )}
                  </div>
                </div> */}
                {/* <div className="col-lg-3 mb-4 mb-lg-0 pl-lg-0">
                  <div className="mb-4 border border-success border-3 card font-weight-bold text-uppercase">
                    <div className="card-header">Membership by gender</div>
                    <div className="card-body">
                      <canvas
                        role="img"
                        height="162"
                        width="162"
                        className="px-0"
                        style={{
                          display: "block",
                          boxSizing: "border-box",
                          height: "162px",
                          width: "162px",
                        }}
                      ></canvas>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row mt-4">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header text-uppercase">
                      Sacco branches
                    </div>
                    <div className="card-body">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Branch Name</th>
                            <th>Branch Code</th>
                            <th>Address</th>
                          </tr>
                        </thead>
                        <tbody>
                          {saccoBranches
                            ?.slice(0, 3)
                            ?.map((branches, index) => (
                              <tr key={index}>
                                <td>{branches?.branch_name}</td>
                                <td>{branches?.branch_code}</td>
                                <td>{branches?.adress}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header text-uppercase">
                      Sacco Withdraw Settings
                    </div>
                    <div className="card-body">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Account Type</th>
                            <th>Minimum Amount</th>
                            <th>Maximum Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {saccoWithdrawSettings
                            ?.slice(0, 3)
                            ?.map((withdrawal_settings, index) => (
                              <tr key={index}>
                                <td>{withdrawal_settings?.account_name}</td>
                                <td>{withdrawal_settings?.minimum_amount}</td>
                                <td>{withdrawal_settings?.maxmum_amount}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mt-3">
                  <div className="card">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        Sacco clients (<span>{saccoClients?.length}</span>)
                      </h2>
                    </div>

                    <div className="card-body">
                      <MaterialReactTable
                        columns={columns1}
                        data={saccoClients}
                        className="table align-items-justify table-flush p-0 m-0"
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableRowSelection
                        enableGrouping
                        enableStickyHeader
                        initialState={{ density: "compact" }}
                        state={{ isLoading: isLoading }}
                        muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                        muiTableHeadCellProps={{
                          //simple styling with the `sx` prop, works just like a style prop in this example
                          sx: {
                            fontWeight: "normal",
                            fontSize: "13px",
                            fontFamily: "Nunito",
                          },
                        }}
                        muiTableBodyCellProps={{
                          sx: {
                            fontWeight: "normal",
                            fontSize: "13px",
                            fontFamily: "Nunito",
                          },
                        }}
                        muiSearchTextFieldProps={{
                          placeholder: "Search",
                          sx: { fontFamily: "Nunito", fontSize: "12px" },
                        }}
                        renderTopToolbarCustomActions={() => (
                          <>
                            <div className="btn-group">
                              <button className="btn btn-sm btn-danger rounded-0">
                                <i className="fa fa-file-pdf"></i>
                              </button>
                              <CSVLink
                                data={saccoClients}
                                headers={headers}
                                filename={"saccos.csv"}
                              >
                                <button className="btn btn-sm btn-success rounded-0">
                                  <i className="fa fa-file-excel"></i>
                                </button>
                              </CSVLink>
                              <button className="btn btn-sm btn-info rounded-0">
                                <i className="fa fa-print"></i>
                              </button>
                              <button
                                onClick={refetch}
                                className="btn btn-sm btn-secondary rounded-0"
                              >
                                <i className="fa fa-rotate-right"></i>
                              </button>
                            </div>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="card font-weight-bold text-white bg-gradient-success text-uppercase">
                    <div className="card-body">
                      Total Loan Applications :{" "}
                      <span>{saccoLoanApplications}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="card font-weight-bold text-white bg-gradient-success text-uppercase">
                    <div className="card-body">
                      Sacco Loan Products : <span>{saccoLoanProducts}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-6">
                  <div className="card mb-3">
                    <div className="card-header text-uppercase">
                     Sacco Users
                    </div>
                    <div className="card-body">
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>User</th>
                            <th>Email</th>
                            <th>Role</th>
                          </tr>
                        </thead>
                        <tbody>
                          {saccoUserss?.slice(0, 3)?.map((users, index) => (
                            <tr key={index}>
                              <td>{users?.user}</td>
                              <td>{users?.email}</td>
                              <td>{users?.user_role}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}

              {/* <div className="row mt-4 mb-4">
                <div className="col mb-4 mb-lg-0">
                  <div className="card ">
                    <div className="card-header">
                      <h2 className="h6 text-uppercase mb-0">Sent emails</h2>
                    </div>
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div>
                            <table className="table">
                              <thead>
                                <th>Adress</th>
                                <th>Subject</th>
                                <th>Message</th>
                                <th>Status</th>
                                <th>Sent At</th>
                              </thead>
                              <tbody>
                                <td>ikaruhanga182@gmail.com</td>
                                <td>Sacco meeting</td>
                                <td>
                                  Don't miss the scheduled meeting at 2:00pm
                                </td>
                                <td>Sent</td>
                                <td>4:00am</td>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row mb-4">
                {/* <div className="col-lg-7 mb-4 mb-lg-0">
                  <div className="card ">
                    <div className="bg-gradient-primary card-header">
                      <h2 className="h6 text-white text-uppercase mb-0">
                        all sms sent
                      </h2>
                    </div>
                    <div className="card-body">
                      <table className="table p-0 table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Message</th>
                            <th>Client</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.slice(0, 5)?.map((SMS, index) => (
                            <tr key={index}>
                              <td>{SMS?.timestamp}</td>
                              <td>{SMS?.message}</td>
                              <td>{SMS?.client}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-lg-5 mb-4 mb-lg-0 pl-lg-0">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="row align-items-center flex-row">
                        <div className="col-lg-5">
                          <h2 className="mb-0 d-flex align-items-center">
                            <span>40</span>
                            <span className="dot bg-info d-inline-block ml-3"></span>
                          </h2>
                          <span className="text-muted text-uppercase small">
                            Daily
                          </span>
                          <hr />
                          <small className="text-muted">SMSs Sent</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center flex-row">
                        <div className="col-lg-5">
                          <h2 className="mb-0 d-flex align-items-center">
                            <span>UGX.10000</span>
                            <span className="dot bg-warning d-inline-block ml-3"></span>
                          </h2>
                          <span className="text-muted text-uppercase small">
                            today's
                          </span>
                          <hr />
                          <small className="text-muted">SMS Income</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row">
                {/* <div className="col-lg-5 mb-4 mb-lg-0">
                  <div className="card mb-3">
                    <div className="card-body">
                      <div className="row align-items-center flex-row">
                        <div className="col-lg-5">
                          <h2 className="mb-0 d-flex align-items-center">
                            <span>50</span>
                            <span className="dot bg-info d-inline-block ml-3"></span>
                          </h2>
                          <span className="text-muted text-uppercase small">
                            today's
                          </span>
                          <hr />
                          <small className="text-muted">
                            USSD Transactions
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center flex-row">
                        <div className="col-lg-5">
                          <h2 className="mb-0 d-flex align-items-center">
                            <span>UGX.20000</span>
                            <span className="dot bg-warning d-inline-block ml-3"></span>
                          </h2>
                          <span className="text-muted text-uppercase small">
                            Daily
                          </span>
                          <hr />
                          <small className="text-muted">USSD Income</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="mb-3 col-lg-7">
                  <div className="card">
                    <div className="bg-gradient-primary card-header">
                      <h2 className="h6 text-white text-uppercase mb-0">
                        all USSD Requests
                      </h2>
                    </div>
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="h5 mb-0 font-weight-bold text-white">
                            <MaterialReactTable
                              columns={columns}
                              data={data}
                              className="table align-items-center mb-0"
                              enableColumnFilterModes
                              enableColumnOrdering
                              enableRowSelection
                              enableGrouping
                              enableStickyHeader
                              muiTableContainerProps={{
                                sx: { maxHeight: "300px" },
                              }}
                              muitheadCellProps={{
                                //simple styling with the `sx` prop, works just like a style prop in this example
                                sx: {
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  fontFamily: "Nunito",
                                },
                              }}
                              muitbodyCellProps={{
                                sx: {
                                  fontWeight: "normal",
                                  fontSize: "13px",
                                  fontFamily: "Nunito",
                                },
                              }}
                              muiSearchTextFieldProps={{
                                placeholder: "Search",
                                sx: {
                                  fontFamily: "Nunito",
                                  fontSize: "12px",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </section>
          </div>
          {/* footer............................ */}
          <AdminFooter />
          {/* footer end............................ */}
        </div>
      </div>
    </>
  );
};

export default SaccoView;
