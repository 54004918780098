import Login from "./components/login-component";

const LoginUser = () => {
    return ( 
        <>
            <Login />
        </>
     );
}
 
export default LoginUser;