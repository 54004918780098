import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useAxiosPrivate from "../middlewares/hooks/useAxiosPrivate";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import toast, { Toaster } from "react-hot-toast";
import MaterialReactTable from "material-react-table";
import AdminFooter from "./components/admin_footer";
import AdminHeader from "./components/admin_header";
import AdminSidebar from "./components/admin_sidebar";
import { Circles } from "react-loader-spinner";
const User = () => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [saccoUsers, setSaccoUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const controller = new AbortController();
  const getSaccoUsers = async () => {
    try {
      const response = await axiosPrivate.get(`/sacco_users/${params.id}`, {
        signal: controller.signal,
      });
      // console.log(response);
      setSaccoUsers(response.data.data.users);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching saccos:", error);
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };

  useEffect(() => {
    getSaccoUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = useCallback(
    async (id) => {
      confirmAlert({
        message: "Are you sure you want to delete this user?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              try {
                await axiosPrivate.delete(`/sacco_users/${id}`);
                toast.success("User deleted successfully");
                // Refetch users after deletion
                setIsLoading(true);
                const response = await axiosPrivate.get(
                  `/sacco_users/${params.id}`
                );
                // setUsers(response.data.data.users);
                setIsLoading(false);
              } catch (error) {
                console.error("Error deleting user:", error);
                if (error?.response?.status === 401) {
                  navigate("/", {
                    state: { from: location },
                    replace: true,
                  });
                }
                toast.error("Failed to delete user");
              }
            },
          },
          {
            label: "No",
          },
        ],
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id]
  );

  const confirmUnlock = (userId) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to unlock this user?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleUnlock(userId),
        },
        {
          label: "No",
          onClick: () => {}, // Do nothing on No click
        },
      ],
    });
  };

  const handleUnlock = useCallback(
    async (userId) => {
      try {
        // Send a PATCH request to update the user's status to "active"
        await axiosPrivate.patch(`/sacco_users/${params.id}`, {
          user: userId,
         
        });
        // Update the specific user's status locally
        setSaccoUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === userId ? { ...user, status: "active" } : user
          )
        );
        // Show success toast message
        toast.success("User unlocked successfully");
      } catch (error) {
        // console.error("Error unlocking user:", error);
        if (error?.response?.status === 401) {
          navigate("/", {
            state: { from: location },
            replace: true,
          });
        }
        toast.error(error?.response?.data?.messages);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.id]
  );

  const columns = useMemo(
    () => [
      {
        id: "user",
        enableClickToCopy: true,
        header: "User",
        accessorFn: (row) => row.user,
        size: 50,
      },
      {
        id: "contact",
        enableClickToCopy: true,
        header: "Contact",
        accessorFn: (row) => row.contact,
        size: 50,
      },
      {
        id: "email",
        enableClickToCopy: true,
        header: "Email",
        accessorFn: (row) => row.email,
        size: 50,
      },
      {
        id: "status",
        enableClickToCopy: true,
        header: "Status",
        accessorFn: (row) => row.status,
        size: 50,
      },
      {
        id: "actions",
        enableClickToCopy: false,
        size: 50,
        header: "Actions",
        accessorFn: (row) => (
          <div className="text-center">
            <button
              className="btn btn-link text-danger font-weight-bold text-xs"
              onClick={() => handleDelete(row.id)}
            >
              Delete
            </button>
            <button
              className="btn btn-link text-primary font-weight-bold text-xs"
              onClick={() => {
                // console.log(row.id);
                // handleUnlock(row.id);
                confirmUnlock(row.id)
              }}
            >
              Unlock
            </button>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <>
      <Toaster />
      {/* .............header...................... */}
      <AdminHeader />
      {/* .............header end..................... */}
      <div className="d-flex align-items-stretch">
        {/* .............siderbar...................... */}
        <AdminSidebar />
        {/* ..............sidebar end..................... */}
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5">
              <div className="row mb-4">
                <div className="col-lg-12 mb-4 mb-lg-0">
                  <div className="card ">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        SACCO Users (<span>{saccoUsers?.length}</span>)
                      </h2>

                      <button
                        className="btn btn-outline-success float-right"
                        // onClick={handleShowModal}
                      >
                        Add Sacco User
                      </button>

                      {/* <OnboardSacco
                        showModal={showModal}
                        handleCloseModal={handleCloseModal}
                        getSaccos={getSaccos}
                      /> */}
                    </div>
                    <div className="card-body text-center">
                      {isLoading ? (
                        // <div>Loading......</div>
                        <div className="text-center">
                          <Circles color="#007bff" height={80} width={80} />
                        </div>
                      ) : saccoUsers.length === 0 ? (
                        <div>No users found</div>
                      ) : (
                        <MaterialReactTable
                          columns={columns}
                          data={saccoUsers}
                          className="table align-items-justify table-flush p-0 m-0"
                          enableColumnFilterModes
                          enableColumnOrdering
                          enableRowSelection
                          enableGrouping
                          enableStickyHeader
                          initialState={{ density: "compact" }}
                          state={{ isLoading: isLoading }}
                          muiTableContainerProps={{
                            sx: { maxHeight: "300px" },
                          }}
                          muiTableHeadCellProps={{
                            //simple styling with the `sx` prop, works just like a style prop in this example
                            sx: {
                              fontWeight: "normal",
                              fontSize: "13px",
                              fontFamily: "Nunito",
                            },
                          }}
                          muiTableBodyCellProps={{
                            sx: {
                              fontWeight: "normal",
                              fontSize: "13px",
                              fontFamily: "Nunito",
                            },
                          }}
                          muiSearchTextFieldProps={{
                            placeholder: "Search",
                            sx: { fontFamily: "Nunito", fontSize: "12px" },
                          }}
                          renderTopToolbarCustomActions={({ table }) => (
                            <>
                              <div className="btn-group">
                                <button className="btn btn-sm btn-danger rounded-0">
                                  <i className="fa fa-file-pdf"></i>
                                </button>

                                <button
                                  className="btn btn-sm btn-success rounded-0"
                                  disabled={
                                    table.getRowModel().rows.length === 0
                                  }
                                  //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                  // onClick={() => handleExportRows(table.getRowModel().rows)}
                                >
                                  <i className="fa fa-file-excel"></i>
                                </button>
                                <button className="btn btn-sm btn-info rounded-0">
                                  <i className="fa fa-print"></i>
                                </button>
                                <button
                                  // onClick={refetch}
                                  className="btn btn-sm btn-dark rounded-0"
                                >
                                  <i className="fa fa-rotate-right"></i>
                                </button>
                              </div>
                            </>
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* footer............................ */}
          <AdminFooter />
          {/* footer end............................ */}
        </div>
      </div>
    </>
  );
};

export default User;
