
const UssdLiquidationTable = ({ saccoLiquidationHistoryData }) => {
    // console.log(saccoLiquidationHistoryData);
    const formatAmount = (number) => {
      // Convert to a number if not already a number
      const num = Number(number);
      // Check if num is a number
      if (isNaN(num)) {
        throw new Error(`${number} is not a number`);
      }
      return num.toFixed(0).replace(/\d(?=(\d{3})+$)/g, "$&,");
    };
    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover align-middle">
          <thead className="table-dark">
            <tr>
              <th>From</th>
              <th>To</th>
              <th>Liquidated Amount</th>
              <th>Liquidation Date</th>
              {/* <th>Remaining Balance</th> */}
            </tr>
          </thead>
          <tbody>
            {saccoLiquidationHistoryData.map((item) => (
              <tr key={item?.id}>
                <td>{item?.start_date}</td>
                <td>{item?.end_date}</td>
                <td>{formatAmount(parseFloat(item?.amount_liquidated))}</td>
                <td>{item?.liquidation_date}</td>
                {/* <td>{formatAmount(parseFloat(item?.remaining_balance))}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  
  export default UssdLiquidationTable;
  