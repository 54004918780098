import { Modal, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ProgressBar from "@badrap/bar-of-progress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { format } from "date-fns";

const PaymentSmsModal = ({ showModal, onClose, getSaccoHistory }) => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const progress = new ProgressBar({ size: 5, color: "#e91e63" });
  const [saving, setSaving] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm();

  // const onSubmit = async (data) => {
  //   try {
  //     const formattedStartDate = format(data.start_date, "yyyy-MM-dd HH:mm:ss");
  //     const formattedEndDate = format(data.end_date, "yyyy-MM-dd HH:mm:ss");
  //     const payload = {
  //       ...data,
  //       start_date: formattedStartDate,
  //       end_date: formattedEndDate,
  //       paid_amount: data.paid_amount,
  //     };
  //     console.log(payload);
  //     const controller = new AbortController();
  //     const response = await axiosPrivate.patch(
  //       `/sms_report/${params.id}`,
  //       payload,
  //       // {
  //       //   headers: {
  //       //     "Content-Type": "application/json",
  //       //   },
  //       // }
  //       {
  //         signal: controller.signal,
  //       }
  //     );
  //     console.log(response);
  //     if (response.status === 200) {
  //       toast.success(response.data.messages, {
  //         style: {
  //           backgroundColor: "#008000", // green background
  //           color: "#ffffff", // white text
  //         },
  //       });
  //       progress.finish();
  //       setDisabled(false);
  //       console.log("Payment data to update:");
  //       getSaccoHistory();
  //       console.log("SMS successfully updated");
  //     } else {
  //       console.error("Failed to update SMS status");
  //     }
  //   } catch (error) {
  //     if (
  //       error.response &&
  //       error.response.data &&
  //       error.response.data.messages
  //     ) {
  //       toast.error(error.response.data.messages, {
  //         style: {
  //           backgroundColor: "red",
  //           color: "black", // Specify the text color
  //         },
  //       });
  //     } else {
  //       console.error("An unexpected error occurred:", error);
  //     }
  //   }
  //   onClose();
  //   reset();
  // };

  const onSubmit = async (data) => {
    try {
      setSaving(true); // Set button status to saving
      const formattedStartDate = format(data.start_date, "yyyy-MM-dd HH:mm:ss");
      const formattedEndDate = format(data.end_date, "yyyy-MM-dd HH:mm:ss");
      const payload = {
        ...data,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        paid_amount: data.paid_amount,
      };
      console.log(payload);
      const controller = new AbortController();
      const response = await axiosPrivate.patch(
        `/sms_report/${params.id}`,
        payload,
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // }
        {
          signal: controller.signal,
        }
      );
      // console.log(response);
      // toast.success(response?.data?.messages);
      const successMessage =
        response?.data?.messages || "Payment Cleared Successfull";
      toast.success(successMessage);
      progress.finish();
      getSaccoHistory();
      onClose();
      reset();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.messages
      ) {
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      } else {
        console.error("An unexpected error occurred:", error);
      }
    } finally {
      setSaving(false); // Reset button status
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        onHide={onClose}
        size="md"
        centered
        backdrop="static"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">Payment Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group col-md-12 input-container">
              <label htmlFor="startDate" className="form-label">
                Start Date
              </label>
              <Controller
                name="start_date"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    className="form-control border-0 shadow form-control-md input-text"
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    showTimeSelect
                    timeFormat="HH:mm:ss"
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    timeIntervals={1}
                    timeCaption="time"
                  />
                )}
              />
              {errors?.start_date?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter start date
                </p>
              )}
            </div>
            <div className="form-group col-md-12 input-container">
              <label htmlFor="startDate" className="form-label">
                End date
              </label>
              <Controller
                name="end_date"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    className="form-control border-0 shadow form-control-md input-text"
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    showTimeSelect
                    timeFormat="HH:mm:ss"
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    timeIntervals={1}
                    timeCaption="time"
                  />
                )}
              />
              {errors?.end_date?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter end date
                </p>
              )}
            </div>
            <div className="form-group col-md-7 input-container">
              <label htmlFor="amountToPay" className="form-label">
                Amount to pay
              </label>
              <input
                type="number"
                name="paid_amount"
                placeholder="Enter Amount to pay*"
                className="form-control border-1 form-control-md input-text"
                {...register("paid_amount", { required: true })}
              />
              {errors?.paid_amount?.type === "required" && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter amount to pay
                </p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button btn="secondary" onClick={onClose}>
              Close
            </Button>
            <Button type="submit" btn="primary">
              {" "}
              {saving ? "Paying..." : "Pay"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default PaymentSmsModal;
