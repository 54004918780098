import { NavLink, Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faExchangeAlt,
  faBuilding,
  faHome,
  faUser,
  faTasks,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import useLogout from "../../middlewares/hooks/useLogout";

const AdminSidebar = () => {
  const logout = useLogout();
  const navigate = useNavigate();
  const signOut = async () => {
    if (window.confirm("Are you sure you want to logout?")) {
      await logout();
      await localStorage.removeItem("verify");
      navigate("/");
    }
  };
  return (
    <>
      <div id="sidebar" className="sidebar py-5  sidenav fixed">
        <div className="text-gray-400 text-uppercase px-3 px-lg-4 py-4 font-weight-bold small headings-font-family">
          AHURIIRE MAIN
        </div>
        <ul className="sidebar-menu list-unstyled py-5">
          <li className="sidebar-list-item" id="links">
            <NavLink to="/admin" className="sidebar-link text-muted ">
              <FontAwesomeIcon
                icon={faHome}
                className="mr-3 text-gray text-muted"
              />
              <span>Dashboard</span>
            </NavLink>
          </li>

          {/* <li className="sidebar-list-item" id="links"><Link to="/branches" className="sidebar-link text-muted"><i className="o-database-1 mr-3 text-gray"></i><span>Branches</span></Link></li> */}

          <li className="sidebar-list-item" id="links">
            <NavLink to="/sacco/onboard" className="sidebar-link text-muted">
              <FontAwesomeIcon
                icon={faBuilding}
                className="mr-3 text-gray text-muted"
              />
              {/* <i className="o-user-details-1 mr-3 text-muted"></i> */}
              <span>Saccos</span>
            </NavLink>
          </li>
          <li className="sidebar-list-item" id="links">
            <NavLink to="/sacco/reporting" className="sidebar-link text-muted">
              <FontAwesomeIcon
                icon={faChartBar} // Replace with the icon representing reporting
                className="mr-3 text-gray text-muted"
              />
              {/* <i className="o-user-details-1 mr-3 text-muted"></i> */}
              <span>Sacco Reports</span>
            </NavLink>
          </li>
          <li className="sidebar-list-item dropdown" id="links">
            <Link
              to="#"
              className="sidebar-link text-muted dropdown-toggle"
              data-bs-toggle="dropdown"
              data-bs-target="#general-reports"
            >
              <FontAwesomeIcon
                icon={faChartBar}
                className="mr-3"
              />
              <span>General Reports</span>
            </Link>
            <div id = "genera_reports"className="dropdown-menu ">
              <NavLink
                to="/all_pending_ussd_report"
                className="dropdown-item"
              >
                Pending USSD Report
              </NavLink>
              <NavLink
                to="/all_liquidated_ussd_report"
                className="dropdown-item"
              >
                Liquidated USSD Report
              </NavLink>
              <NavLink
                to="/all_ussd"
                className="dropdown-item"
              >
                All USSD Report
              </NavLink>
            </div>
          </li>
          <li className="sidebar-list-item" id="links">
            <NavLink
              to="/sacco/transactions"
              className="sidebar-link text-muted"
            >
              <FontAwesomeIcon
                icon={faExchangeAlt} // Replace with the icon representing transactions
                className="mr-3 text-gray text-muted"
              />
              {/* <i className="o-user-details-1 mr-3 text-muted"></i> */}
              <span>Daily Transactions</span>
            </NavLink>
          </li>
          <li className="sidebar-list-item">
            <NavLink to="/sacco/users" className="sidebar-link text-muted">
              {/* <i className="o-id-card-1 mr-3 text-gray text-muted"></i> */}
              <FontAwesomeIcon
                icon={faUser}
                className="mr-3 text-gray text-muted"
              />
              <span>General Sacco Users</span>
            </NavLink>
          </li>

          <li className="sidebar-list-item">
            <NavLink to="/system_users" className="sidebar-link text-muted">
              {/* <i className="o-id-card-1 mr-3 text-gray text-muted"></i> */}
              <FontAwesomeIcon
                icon={faUser}
                className="mr-3 text-gray text-muted"
              />
              <span>Admin Users</span>
            </NavLink>
          </li>
          <li className="sidebar-list-item">
            <NavLink to="/activity/user" className="sidebar-link text-muted">
              {/* <i className="o-id-card-1 mr-3 text-gray text-muted"></i> */}
              <FontAwesomeIcon
                icon={faTasks}
                className="mr-3 text-gray text-muted"
              />
              <span>Activity</span>
            </NavLink>
          </li>
          <li className="sidebar-list-item">
            <Link onClick={signOut} className="sidebar-link text-muted">
              {/* <i className="o-exit-1 mr-3 text-gray text-muted"></i> */}
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="mr-3 text-gray text-muted"
              />
              <span>Logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default AdminSidebar;
