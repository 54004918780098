import axios from "../../config/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const verify = JSON.parse(localStorage.verify)
    // console.log(verify);
    // very considerate work for sure 
    const REFRESH_URL = '/sessions/'+verify?.id
    const refresh = async () => {
        
        const response = await axios.patch(REFRESH_URL, JSON.stringify({ refresh_token: verify?.refresh }), { headers:{ 'Authorization': verify?.access} })
        setAuth(prev => {
            //  console.log(JSON.stringify(prev))
            const verify = { refresh : response.data.data.refresh_token,  id: response.data.data.session_id, access: response.data.data.access_token  }
            console.log(verify);
            localStorage['verify'] = JSON.stringify(verify);
            // console.log(localStorage);
            // localStorage['verify'] = { "refresh" : response.data.data.refresh_token, "id": response.data.data.id };
            return {...prev, accesstoken: response.data.data.access_token, refreshtoken: response.data.data.refresh_token }
        })
        return response.data.data.access_token;
    }
    
    return refresh;

}
 
export default useRefreshToken;

// import axios from "../../config/axios";
// import useAuth from "./useAuth";

// const useRefreshToken = () => {
//     const { setAuth } = useAuth();
//     const verify = JSON.parse(localStorage.verify)
//     // very considerate work for sure 
//     const REFRESH_URL = '/sessions/'+verify?.id
    // const refresh = async () => {
        
    //     const response = await axios.patch(REFRESH_URL, JSON.stringify({ refresh_token: verify?.refresh }), { headers:{ 'Authorization': verify?.access} })
    //     console.log(response);
    //     setAuth(prev => {
    //         // console.log(JSON.stringify(prev))
    //         const verify = { refresh : response.data.data.refresh_token,  id: response.data.data.session_id, access: response.data.data.access_token  }
    //         localStorage['verify'] = JSON.stringify(verify);
    //         // localStorage['verify'] = { "refresh" : response.data.data.refresh_token, "id": response.data.data.id };
    //         console.log(localStorage); // Log local storage
    //         return {...prev, accesstoken: response.data.data.access_token, refreshtoken: response.data.data.refresh_token }
    //     })
    //     return response.data.data.access_token;
    // }
    
//     const refresh = async () => {
//         try {
//             const response = await axios.patch(REFRESH_URL, JSON.stringify({ refresh_token: verify?.refresh }), { headers:{ 'Authorization': verify?.access} });
//             console.log(response);
//             const { access_token, refresh_token, session_id } = response.data.data;
//             const newVerify = { id: session_id, access: access_token, refresh: refresh_token };
//             localStorage['verify'] = JSON.stringify(newVerify);
//             console.log(localStorage); // Verify that all tokens are stored correctly
//             setAuth(prev => ({
//                 ...prev,
//                 accesstoken: access_token,
//                 refreshtoken: refresh_token
//             }));
//             return access_token;
//         } catch (error) {
//             console.error("Error refreshing token:", error);
//             // Handle error appropriately
//         }
//     }
    
//     return refresh;

// }
 
// export default useRefreshToken;

