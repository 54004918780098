const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    return (
      <div className="pagination">
        <button
          className="btn btn-dark btn-sm rounded-0"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className="btn btn-sm btn-link text-dark rounded-0">
          {currentPage}
        </span>
        <button
          className="btn btn-dark btn-sm rounded-0"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    );
  };
  export default Pagination;