import React, { useState, useMemo, useEffect, useCallback } from "react";
import AdminFooter from "./components/admin_footer";
import AdminHeader from "./components/admin_header";
import AdminSidebar from "./components/admin_sidebar";
import { MaterialReactTable } from "material-react-table";
import useAxiosPrivate from "../middlewares/hooks/useAxiosPrivate";
import { Link, useNavigate, useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
// import { CSVLink } from "react-csv";
import { ExportToCsv } from "export-to-csv";
const SaccosSMS = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [saccos, setSaccos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const controller = new AbortController();
  const getSaccos = async () => {
    try {
      const response = await axiosPrivate.get("/saccos", {
        signal: controller.signal,
      });
      setSaccos(response.data.data.saccos);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching saccos:", error);
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };

  useEffect(() => {
    getSaccos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDetele = useCallback(
    (id) => {
      confirmAlert({
        // : 'Confirm to submit',s
        message: " Are you sure to do this..",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              try {
                const controller = new AbortController();
                const response = await axiosPrivate.delete("/saccos/" + id, {
                  signal: controller.signal,
                });
                toast.success(response?.data?.messages);
              } catch (error) {
                if (!error?.response) {
                  toast("No server response");
                } else {
                  console.log(error?.response);
                  if (error?.response?.status === 401) {
                    navigate("/", {
                      state: { from: location },
                      replace: true,
                    });
                  }
                  toast.error(error?.response?.data?.messages, {
                    style: {
                      background: "#e91e63",
                      fontSize: "13px",
                      color: "#fff",
                    },
                  });
                }
              }
            },
          },
          {
            label: "No",
            // onClick: () => alert('Click No')
          },
        ],
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns = useMemo(
    () => [
      {
        id: "sacco_name", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Sacco Name",
        accessorFn: (row) => row.sacco_name,
        size: 50,
      },
      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: false,
        size: 50,
        header: "Action",
        accessorFn: (row) => (
          <div className="align-middle text-center text-sm">
            <Link
              to={`/sacco/smsusage/${row.id}`}
              className="text-secondary font-weight-bold text-xs mr-3"
            >
              view
            </Link>
            {
              <button
                className="btn btn-link text-white rounded-0 btn-sm p-0"
                onClick={() => handleDetele(row.id)}
                aria-expanded="false"
              >
                <i className="text-danger fa fa fa-trash"></i>
              </button>
            }
          </div>
        ),
      },
    ],
    [handleDetele]
  );

  const refetch = useCallback(async () => {
    setIsLoading(true);
    await getSaccos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  return (
    <>
      <Toaster />
      {/* .............header...................... */}
      <AdminHeader />
      {/* .............header end..................... */}
      <div className="d-flex align-items-stretch">
        {/* .............siderbar...................... */}
        <AdminSidebar />
        {/* ..............sidebar end..................... */}
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5">
              <div className="row mb-4">
                <div className="col-lg-12 mb-4 mb-lg-0">
                  <div className="card ">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        ussd transaction history
                      </h2>
                    </div>
                    <div className="card-body">
                      <MaterialReactTable
                        columns={columns}
                        data={saccos}
                        className="table align-items-justify table-flush p-0 m-0"
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableRowSelection
                        enableGrouping
                        enableStickyHeader
                        initialState={{ density: "compact" }}
                        state={{ isLoading: isLoading }}
                        muiTableContainerProps={{ sx: { maxHeight: "300px" } }}
                        muiTableHeadCellProps={{
                          //simple styling with the `sx` prop, works just like a style prop in this example
                          sx: {
                            fontWeight: "normal",
                            fontSize: "13px",
                            fontFamily: "Nunito",
                          },
                        }}
                        muiTableBodyCellProps={{
                          sx: {
                            fontWeight: "normal",
                            fontSize: "13px",
                            fontFamily: "Nunito",
                          },
                        }}
                        muiSearchTextFieldProps={{
                          placeholder: "Search",
                          sx: { fontFamily: "Nunito", fontSize: "12px" },
                        }}
                        renderTopToolbarCustomActions={({ table }) => (
                          <>
                            <div className="btn-group">
                              <button className="btn btn-sm btn-danger rounded-0">
                                <i className="fa fa-file-pdf"></i>
                              </button>

                              <button
                                className="btn btn-sm btn-success rounded-0"
                                disabled={table.getRowModel().rows.length === 0}
                                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                onClick={() =>
                                  handleExportRows(table.getRowModel().rows)
                                }
                              >
                                <i className="fa fa-file-excel"></i>
                              </button>
                              <button className="btn btn-sm btn-info rounded-0">
                                <i className="fa fa-print"></i>
                              </button>
                              <button
                                onClick={refetch}
                                className="btn btn-sm btn-dark rounded-0"
                              >
                                <i className="fa fa-rotate-right"></i>
                              </button>
                            </div>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* footer............................ */}
          <AdminFooter />
          {/* footer end............................ */}
        </div>
      </div>
    </>
  );
};

export default SaccosSMS;
