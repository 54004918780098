import React from "react";
import { Link } from "react-router-dom";
const AdminFooter = () => {
  return (
    <>
      <footer className="footer bg-white shadow align-self-end py-3 px-xl-5 w-100 fixed-bottom">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 text-center text-md-left text-secondary">
              <p className="mb-2 mb-md-0">
                iRembo Finance &copy; <span id="year"></span>
              </p>
            </div>
            <div className="col-md-6 text-center text-md-right text-gray-400">
              <p className="mb-0">
                <span className="text-dark">Powered by</span>{" "}
                <Link href="#" className="external text-warning">
                  Mob
                  <i
                    className="fa fa-info-circle text-success"
                    aria-hidden="true"
                  ></i>
                  tungo
                </Link>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default AdminFooter;
