import React, { useState, useMemo, useEffect, useCallback } from "react";
import AdminFooter from "./components/admin_footer";
import AdminHeader from "./components/admin_header";
import AdminSidebar from "./components/admin_sidebar";
import { MaterialReactTable } from "material-react-table";
import useAxiosPrivate from "../middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";


// import { CSVLink } from "react-csv";
import { ExportToCsv } from "export-to-csv"; 

const SaccoDailySms = () => {

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [dailySms, setDailySms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [saccosName, setSaccosName] = useState("");
  const params = useParams();

const controller = new AbortController();
const getDailyTransactions = async () => {
  try {
    const response = await axiosPrivate.get(`/daily_withdraw/${parseInt(params.id)}`, {
      signal: controller.signal,
    });
    setDailySms(response.data.data.transactions);
    // console.log(response.data.data.deposit_transactions);
    setIsLoading(false);
  } catch (error) {
    console.error("Error fetching withdraw transactions:", error);
    if (error?.response?.status === 401) {
      navigate("/", {
        state: { from: location },
        replace: true,
      });
    }
  }
};

const getSaccosName = async () => {
  try {
    const response = await axiosPrivate.get(`/saccos/${params.id}`, {
      signal: controller.signal,
    });
    setSaccosName(response.data.data.sacco.sacco_name);
  } catch (error) {
    console.error("Error fetching saccos:", error);
    if (error?.response?.status === 401) {
      navigate("/", {
        state: { from: location },
        replace: true,
      });
    }
  }
};

useEffect(() => {
  getSaccosName();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []); 


useEffect(() => {
  getDailyTransactions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  const columns = useMemo(
    () => [
      {
        id: "id", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Transaction Id",
        accessorFn: (row) => row.id,
        size: 50,
      },
      {
        id: "withdraw_amount", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Amount",
        accessorFn: (row) => row.withdraw_amount,
        size: 50,
      },
      {
        id: "withdraw_charge", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Withraw Charge",
        accessorFn: (row) => row.withdraw_charge,
        size: 50,
      },
      {
        id: "reverse_transaction_status", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Reverse Transaction Status",
        accessorFn: (row) => row.reverse_transaction_status,
        size: 50,
      },
      {
        id: "client", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Client Name",
        accessorFn: (row) => row.client,
        size: 50,
      },
      {
        id: "account", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Account",
        accessorFn: (row) => row.account,
        size: 50,
      } 
    ],
    []
  );

  const refetch = useCallback(async () => {
    setIsLoading(true);
    await getDailyTransactions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };



  return (
    <>
      {/* <Toaster /> */}
      {/* .............header...................... */}
      <AdminHeader />
      {/* .............header end..................... */}
      <div className="d-flex align-items-stretch">
        {/* .............siderbar...................... */}
        <AdminSidebar />
        {/* ..............sidebar end..................... */}
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5">
              <div className="row mb-4">
                <div className="col-lg-12 mb-4 mb-lg-0">
                  <div className="card ">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        {saccosName}{" "}Today SMS (<span>{dailySms?.length}</span>)
                      </h2>  
                    </div>
                    <div className="card-body">
                    {dailySms.length === 0 ? (
                        <div
                          style={{
                            textAlign: "center",
                            padding: "20px",
                            fontSize: "18px",
                            color: "#555",
                          }}
                        >
                          No daily Sms
                        </div>
                      ) : (
                        <MaterialReactTable
                          columns={columns}
                          data={dailySms}
                          className="table align-items-justify table-flush p-0 m-0"
                          enableColumnFilterModes
                          enableColumnOrdering
                          enableRowSelection
                          enableGrouping
                          enableStickyHeader
                          initialState={{ density: "compact" }}
                          state={{ isLoading: isLoading }}
                          muiTableContainerProps={{
                            sx: { maxHeight: "300px" },
                          }}
                          muiTableHeadCellProps={{
                            //simple styling with the `sx` prop, works just like a style prop in this example
                            sx: {
                              fontWeight: "normal",
                              fontSize: "13px",
                              fontFamily: "Nunito",
                            },
                          }}
                          muiTableBodyCellProps={{
                            sx: {
                              fontWeight: "normal",
                              fontSize: "13px",
                              fontFamily: "Nunito",
                            },
                          }}
                          muiSearchTextFieldProps={{
                            placeholder: "Search",
                            sx: { fontFamily: "Nunito", fontSize: "12px" },
                          }}
                          renderTopToolbarCustomActions={({ table }) => (
                            <>
                              <div className="btn-group">
                                <button className="btn btn-sm btn-danger rounded-0">
                                  <i className="fa fa-file-pdf"></i>
                                </button>

                                <button
                                  className="btn btn-sm btn-success rounded-0"
                                  disabled={
                                    table.getRowModel().rows.length === 0
                                  }
                                  //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                  onClick={() =>
                                    handleExportRows(table.getRowModel().rows)
                                  }
                                >
                                  <i className="fa fa-file-excel"></i>
                                </button>
                                <button className="btn btn-sm btn-info rounded-0">
                                  <i className="fa fa-print"></i>
                                </button>
                                <button
                                  onClick={refetch}
                                  className="btn btn-sm btn-dark rounded-0"
                                >
                                  <i className="fa fa-rotate-right"></i>
                                </button>
                              </div>
                            </>
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* footer............................ */}
          <AdminFooter />
          {/* footer end............................ */}
        </div>
      </div>
    </>
  );
};

export default SaccoDailySms;
