import "./App.css";
import { Routes, Route } from "react-router-dom";
import Layout from "./layouts/layout";
// import LoginUser from "./pages/authentication/login.js";
import RequiredAuth from "./middlewares/hooks/requiredLogin";
import PersitLogin from "./middlewares/global/persistentLogin";
// import SaccoUserAccounts from "./pages/sacco_user_accounts";
import SaccoView from "./pages/sacco_view";
import SaccosOnboard from "./pages/saccos";
import AdminDashboard from "./pages/dashboards/admin_dashboard";
import UserActivity from "./pages/user/components/user-activity";
import ChangeUserPassword from "./pages/user/components/change_user_passwords";
import LoginUser from "./pages/authentication/login";
import FailedUssdReport from "pages/failed_ussd_report";
import UssdIncomeReport from "pages/general_ussd_report";
import SaccoUssdReport from "./pages/sacco_ussd_report";
import LiquidateUssd from "pages/liquidate_ussd";
import PaySMS from "pages/pay-sms";
import SaccoHistory from "pages/sacco_history";
import SmsView from "pages/sms_view";
import SmsReport from "pages/sms_report";
import LiquidationHistory from "pages/sacco_liquidation_history";
import SmsPaymentHistory from "pages/sacco_sms_payment_history";
import SaccosUssd from "pages/sacco_ussd";
import UssdView from "pages/ussd_view";
import SaccosUsers from "pages/sacco-users";
import User from "pages/user";
import AdminUserAccounts from "pages/admin_user_accounts";
// import SavingsReport from "pages/savings_report";
import ReportView from "pages/sacco_reports";
import SaccoReporting from "pages/sacco_general_savings";
import SaccoDailySavings from "pages/daily_savings";
import SaccosGeneralWithdraws from "pages/sacco_general_withdraws";
import WithdrawsView from "pages/sacco_withdraws_view";
import WithdrawsReport from "pages/withdraws_report";
import SaccoDailyWithdraws from "pages/daily_withdraws";
import SaccoDailySms from "pages/daily_sms";
import SaccoDailyUssd from "pages/daily_ussd";
import SmsIncomeReport from "pages/general_sms_income";
import UssdIncome from "pages/general_ussd_income";
import LiquidatedUssdReport from "pages/liquidated_ussd_report";
import TransactionsView from "pages/sacco_daily_transactions";
import SaccoTransactions from "pages/sacco_transactions";
import GeneralUssdReport from "pages/all_ussd_report";
import GeneralLiquidatedUssdReport from "pages/all_liquidated_ussd";
import PaidSmsReport from "pages/paid_sms_report";
import SaccoSavingsReport from "pages/sacco_savings_report";
import SaccoAccountBalanceReport from "pages/balances_report";
import AllUssdReport from "pages/pending _and_liquidated_report";
//import SaccoUser from "pages/user";
function App() {
  return (
    <>
      <Routes>
        <Route path="" element={<Layout />}>
          <Route path="/" element={<LoginUser />} />
          <Route element={<PersitLogin />}>
            <Route element={<RequiredAuth />}>
              <Route path="/admin" element={<AdminDashboard />} />
              <Route path="sacco/view" element={<SaccoView />} />
              <Route path="saccos/:id" element={<SaccoView />} />
              <Route path="sacco/onboard" element={<SaccosOnboard />} />
              <Route path="sacco/daily_sms/:id" element={<SaccoDailySms />} />
              <Route path="sacco/daily_ussd/:id" element={<SaccoDailyUssd />} />
              <Route path="sacco/daily_saving/:id" element={<SaccoDailySavings />} />
              <Route path="sacco/daily_withdraw/:id" element={<SaccoDailyWithdraws />} />
              <Route
                path="sacco/sms_pay_history/:id"
                element={<SmsPaymentHistory />}
              />
              <Route
                path="sacco/liquidation_history/:id"
                element={<LiquidationHistory />}
              />
              {/* <Route path="sacco/smsusage" element={<SmsView />} /> */}
              {/* <Route path="sacco/smsusage" element={<SaccoHistory />} /> */}
              {/* <Route path="sacco/smsusage" element={<SmsView />} /> */}
              {/* <Route path="sacco/sms_report/:id" element={<SmsReport />} /> */}
              {/* <Route path="sacco/smsusage/:id" element={<SmsReport />} /> */}
              <Route path="sacco/history" element={<SaccoHistory />} />
              <Route path="sacco/history/:id" element={<SmsView />} />
              {/* <Route path="sacco/smsusage/:id" element={<SmsReport />} /> */}
              <Route
                path="sacco/smsusage/sms_report/:id"
                element={<SmsReport />}
              />
              <Route
                path="sacco/sms/daily_sms/:id"
                element={<SaccoDailySms />}
              />

              <Route path="sacco/reporting" element={<SaccoReporting />} />
              <Route path="sacco/reporting/:id" element={<ReportView />} />
              <Route
                path="sacco/sms/sms_report/:id"
                element={<SmsReport />}
              />
               <Route
                path="sacco/sms/paid_sms_report/:id"
                element={<PaidSmsReport />}
              />
               <Route
                path="sacco/savings_report/:id"
                element={<SaccoSavingsReport />}
              />
               <Route
                path="sacco/account_balance_report/:id"
                element={<SaccoAccountBalanceReport />}
              />
              <Route path="sacco/transactions" element={<SaccoTransactions />} />
              <Route path="sacco/transactions/:id" element={<TransactionsView />} />
              <Route
                path="sacco/savings/daily_savings/:id"
                element={<SaccoDailySavings />}
              />

              <Route
                path="sacco/withdraws"
                element={<SaccosGeneralWithdraws />}
              />
              <Route path="sacco/withdraws/:id" element={<WithdrawsView />} />
              <Route
                path="sacco/withdraws/daily_withdraws/:id"
                element={<SaccoDailyWithdraws />}
              />
              <Route
                path="sacco/withdraws/withdraws_report/:id"
                element={<WithdrawsReport />}
              />

              <Route path="sacco/ussdusage" element={<SaccosUssd />} />
              <Route path="sacco/ussdusage/:id" element={<UssdView />} />
              {/* <Route path="sacco/smsusage/:id" element={<SmsReport />} /> */}
              <Route
                path="sacco/ussdusage/ussd_report/:id"
                element={<SaccoUssdReport />}
              />
              <Route
                path="sacco/liquidated_ussd_report/:id"
                element={<LiquidatedUssdReport />}
              />
              <Route
                path="sacco/ussdusage/liquidate_ussd/:id"
                element={<LiquidateUssd />}
              />
              <Route
                path="sacco/ussdusage/failed_ussd/:id"
                element={<FailedUssdReport />}
              />
              <Route
                path="sacco/ussd/daily_ussd/:id"
                element={<SaccoDailyUssd />}
              />

              <Route path="sacco/users" element={<SaccosUsers />} />
              <Route path="sacco/users/:id" element={<User />} />
              {/* <Route path="sacco/smsusage/:id" element={<SmsReport />} /> */}
              <Route path="sacco/users/user/:id" element={<User />} />

              <Route path="sacco/ussd_report" element={<SaccoUssdReport />} />
              <Route
                path="general_ussd_report"
                element={<UssdIncomeReport />}
              />
              <Route path="general_sms_income" element={<SmsIncomeReport />} />
              <Route path="general_ussd_income" element={<UssdIncome />} />
              <Route
                path="/all_pending_ussd_report"
                element={<GeneralUssdReport />}
              />
              <Route
                path="/all_liquidated_ussd_report"
                element={<GeneralLiquidatedUssdReport />}
              />
              <Route
                path="/all_ussd"
                element={<AllUssdReport />}
              />
              <Route path="/system_users" element={<AdminUserAccounts />} />
              <Route path="/sacco/liquidate" element={<LiquidateUssd />} />
              <Route path="/pay-sms" element={<PaySMS />} />
              {/* <Route path="/users" element={<User />} /> */}
              <Route path="profile/user" element={<ChangeUserPassword />} />
              <Route path="activity/user" element={<UserActivity />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;