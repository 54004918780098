import axios from "axios";
// const BASE_URL = "https://adminapis.irembofinance.com"
 const BASE_URL = "https://adminapis.irembofinance.com"
    // const BASE_URL = "https://sandbox.irembofinance.com/apis/admin"
      // const BASE_URL = "http://localhost/dashboard_backend"

export default axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
});

export const axiosPrivate = axios.create({
        baseURL : BASE_URL,
        headers: {'Content-Type': 'application/json'},
        withCredentials: false

})