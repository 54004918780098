import Logo from "../../assets/images/img/logo.png";
import Favicon from "../../assets/images/img/favicon.png";
import { Link } from "react-router-dom";

const AdminHeader = () => {
  return (
    <>
      <header className="header sticky-top">
        <nav className="navbar navbar-expand-lg px-4 py-2 bg-white shadow">
          <Link
            to="/"
            className="sidebar-toggler text-gray-500 mr-4 mr-lg-5 lead"
          >
            <i className="fas fa-align-left"></i>
          </Link>
          <Link
            href="dashboard"
            className="navbar-brand font-weight-bold text-uppercase text-base"
          >
            <img
              className="img-fluid"
              style={{ maxHeight: "8rem" }}
              src={Logo}
              alt="Irembo Finance"
            />
          </Link>

          <ul className="ml-auto d-flex align-items-center list-unstyled mb-0">
            <li className="nav-item">
              <div className="d-none d-lg-block">
                <div className="position-relative mb-0">
                  <p className="p-2 mt-3 mr-5">
                    USSD code: <span id="saccocode"></span>{" "}
                  </p>
                </div>
              </div>
            </li>
            <li className="nav-item dropdown mr-3">
              <Link
                id="notifications"
                href=""
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="nav-link dropdown-toggle text-gray-400 px-1"
              >
                <i className="fa fa-bell"></i>
                <span className="notification-icon"></span>
              </Link>
              <div aria-labelledby="notifications" className="dropdown-menu">
                <Link href="#" className="dropdown-item">
                  <div className="d-flex align-items-center">
                    <div className="icon icon-sm bg-violet text-white">
                      <i className="fas fa-money-bill-alt"></i>
                    </div>
                    <div className="text ml-2">
                      <p className="mb-0">Savings (0)</p>
                    </div>
                  </div>
                </Link>
                <Link href="#" className="dropdown-item">
                  <div className="d-flex align-items-center">
                    <div className="icon icon-sm bg-green text-white">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="text ml-2">
                      <p className="mb-0">Message (0)</p>
                    </div>
                  </div>
                </Link>
                <Link href="#" className="dropdown-item">
                  <div className="d-flex align-items-center">
                    <div className="icon icon-sm bg-blue text-white">
                      <i className="fas fa-upload"></i>
                    </div>
                    <div className="text ml-2">
                      <p className="mb-0">Member (0)</p>
                    </div>
                  </div>
                </Link>
                <Link href="#" className="dropdown-item">
                  <div className="d-flex align-items-center">
                    <div className="icon icon-sm bg-danger text-white">
                      <i className="fas fa-money-check"></i>
                    </div>
                    <div className="text ml-2">
                      <p className="mb-0">Withdraws (0)</p>
                    </div>
                  </div>
                </Link>
                <div className="dropdown-divider"></div>
                <Link href="#" className="dropdown-item text-center">
                  <small className="font-weight-bold headings-font-family text-uppercase">
                    Notifications(0)
                  </small>
                </Link>
              </div>
            </li>
            <li className="nav-item dropdown ml-auto">
              <Link
                id="userInfo"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                className="nav-link dropdown-toggle"
              >
                <img
                  src={Favicon}
                  alt="John Doe"
                  style={{ maxWidth: "2.5rem" }}
                  className="img-fluid rounded-circle shadow"
                />
              </Link>
              <div aria-labelledby="userInfo" className="dropdown-menu">
                <Link href="#" className="dropdown-item">
                  <strong
                    className="d-block text-uppercase headings-font-family"
                    id="sacconame"
                  ></strong>
                  <small id="saccoemail"></small>
                </Link>
                <div className="dropdown-divider"></div>
                <Link href="sacco" className="dropdown-item">
                  Settings
                </Link>
                <Link to="/activity" className="dropdown-item">
                  Activity log
                </Link>
                <div className="dropdown-divider"></div>
                <Link to="/" id="logout" className="dropdown-item">
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default AdminHeader;
