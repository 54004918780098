import AdminFooter from "./components/admin_footer";
import AdminHeader from "./components/admin_header";
import AdminSidebar from "./components/admin_sidebar";
import toast, { Toaster } from "react-hot-toast";
import { useParams, Link } from "react-router-dom";

const SmsView = () => {
  const params = useParams();
  return (
    <>
      <Toaster />
      {/* .............header...................... */}
      <AdminHeader />
      {/* .............header end..................... */}
      <div className="d-flex align-items-stretch">
        {/* .............siderbar...................... */}
        <AdminSidebar />
        {/* ..............sidebar end..................... */}
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5">
              <div className="row mb-4">
                <div className="col-lg-12 mb-4 mb-lg-0">
                  <div className="card ">
                    <div className="card-header">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        Sacco SMS
                      </h2>
                    </div>
                    <div className="card-body">
                      <div className="row mb-3">
                        <div className="col-md-3 mb-4">
                          <div className="card">
                            <div className="card-body">
                              {/* <Link to={`/sacco/smsusage/sms_report${params.id}`}>View SMS Report</Link> */}
                              <Link
                                to={`/sacco/smsusage/sms_report/${parseInt(
                                  params.id
                                )}`}
                              >
                                View SMS Report
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-4">
                          <div className="card">
                            <div className="card-body">
                              {/* <Link to={`/sacco/smsusage/sms_report${params.id}`}>View SMS Report</Link> */}
                              <Link
                                to={`/sacco/smsusage/daily_sms/${parseInt(
                                  params.id
                                )}`}
                              >
                                View Daily SMS
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-4">
                          <div className="card">
                            <div className="card-body">
                              {/* <Link to={`/sacco/smsusage/sms_report${params.id}`}>View SMS Report</Link> */}
                              <Link
                                to={`/sacco/smsusage/paid_sms_report/${parseInt(
                                  params.id
                                )}`}
                              >
                                View paid SMS
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-4">
                          <div className="card">
                            <div className="card-body">
                              {/* <Link to={`/sacco/smsusage/sms_report${params.id}`}>View SMS Report</Link> */}
                              <Link
                                to={`/sacco/smsusage/sms_income_report/${parseInt(
                                  params.id
                                )}`}
                              >
                                View SMS Income
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3 mb-4">
                          <div className="card">
                            <div className="card-body">
                              {/* <Link to={`/sacco/smsusage/sms_report${params.id}`}>View SMS Report</Link> */}
                              <Link
                                to={`/sacco/smsusage/unpaid_sms_report/${parseInt(
                                  params.id
                                )}`}
                              >
                                View Unpaid SMS
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* footer............................ */}
          <AdminFooter />
          {/* footer end............................ */}
        </div>
      </div>
    </>
  );
};

export default SmsView;
