import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const LiqiudateUssdModal = ({
  showModal,
  onClose,
  getSaccoLiquidationHistory,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const params = useParams();
  const progress = new ProgressBar({ size: 5, color: "green" });
  const [saving, setSaving] = useState(false); // State to manage button status

  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setSaving(true); // Set button status to saving
      // Convert the date strings to Date objects
      const startDate = new Date(data.start_date);
      const endDate = new Date(data.end_date);

      // Format the dates to Y-m-d H:i:s
      const formattedStartDate = `${startDate.getFullYear()}-${(
        startDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${startDate
        .getDate()
        .toString()
        .padStart(2, "0")} ${startDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${startDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${startDate
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;
      const formattedEndDate = `${endDate.getFullYear()}-${(
        endDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${endDate
        .getDate()
        .toString()
        .padStart(2, "0")} ${endDate
        .getHours()
        .toString()
        .padStart(2, "0")}:${endDate
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${endDate.getSeconds().toString().padStart(2, "0")}`;

      const formattedData = {
        ...data,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        amount_to_liquidate: data.amount_to_liquidate,
      };
      // console.log(formattedData);
      const controller = new AbortController();
      const response = await axiosPrivate.patch(
        `/liquidation/${params.id}`,
        formattedData,
        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        // }
        {
          signal: controller.signal,
        }
      );
      // toast.success(response?.data?.messages);
      const successMessage =
        response?.data?.messages || "USSD transactions liquidated successfully";
      toast.success(successMessage);
      progress.finish();
      getSaccoLiquidationHistory();
      onClose();
      reset();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.messages
      ) {
        toast.error(error?.response?.data?.messages, {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      } else {
        console.error("An unexpected error occurred:", error);
      }
    } finally {
      setSaving(false); // Reset button status
    }
  };

  return (
    <>
      <Toaster />
      <Modal
        show={showModal}
        onHide={onClose}
        size="md"
        centered
        backdrop="static"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              Sacco Liquidation Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Start Date Input */}
            <div className="form-group col-md-12 input-container">
              <label htmlFor="start_date" className="form-label">
                Start Date
              </label>
              <Controller
                name="start_date"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    className="form-control border-0 shadow form-control-md input-text"
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    showTimeSelect
                    timeFormat="HH:mm:ss"
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    timeIntervals={1}
                    timeCaption="time"
                  />
                )}
              />
              {errors.start_date && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please Select Start Date
                </p>
              )}
            </div>

            {/* End Date Input */}
            <div className="form-group col-md-12 input-container">
              <label htmlFor="end_date" className="form-label">
                End Date
              </label>
              <Controller
                name="end_date"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    className="form-control border-0 shadow form-control-md input-text"
                    selected={field.value}
                    onChange={(date) => field.onChange(date)}
                    showTimeSelect
                    timeFormat="HH:mm:ss"
                    dateFormat="yyyy-MM-dd HH:mm:ss"
                    timeIntervals={1}
                    timeCaption="time"
                  />
                )}
              />
              {errors.end_date && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please Select End Date
                </p>
              )}
            </div>

            {/* Amount to Liquidate Input */}
            <div className="form-group col-md-7 input-container">
              <label htmlFor="amountToLiquidate" className="form-label">
                Amount to Liquidate
              </label>
              <input
                type="number"
                name="amount_to_liquidate"
                placeholder="Enter Amount to liquidate*"
                className="form-control border-0 shadow form-control-md input-text"
                style={{ textAlign: "center" }}
                {...register("amount_to_liquidate", {
                  required: true,
                })}
              />
              {errors.amount_to_liquidate && (
                <p className="text-small m-0 px-1 text-start text-danger help-block">
                  Please enter amount to liquidate
                </p>
              )}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button btn="secondary" onClick={onClose}>
              Close
            </Button>
            <Button type="submit" btn="primary">
              {saving ? "Liquidating..." : "Liquidate"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default LiqiudateUssdModal;
