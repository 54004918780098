import React from "react";

const WithdrawsReportTable = ({ messages }) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-hover align-middle">
        <thead className="table-dark">
          <tr>
            <th>TransactionId</th>
            <th>Client Name</th>
            <th>Account Type</th>
            <th>Withdraw Amount</th>
            <th>Withdraw Method</th>
            <th>Withdraw Charge</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((message, index) => (
            <tr key={index}>
              <td>{message?.contact}</td>
              <td>{message?.message}</td>
              <td>{message?.length}</td>
              <td>{message?.pay_status}</td>
              <td>{message?.sent_at}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default WithdrawsReportTable;
