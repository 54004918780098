
import AdminFooter from "./components/admin_footer";
import AdminHeader from "./components/admin_header";
import AdminSidebar from "./components/admin_sidebar";
import { useParams,  Link } from "react-router-dom";

const WithdrawsView = () => {
  const params = useParams();
  return (
    <>
      {/* .............header...................... */}
      <AdminHeader />
      {/* .............header end..................... */}
      <div className="d-flex align-items-stretch">
        <AdminSidebar />
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5">
              <div className="row mb-3">
                <div className="col-md-4 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to={`/sacco/withdraws/withdraws_report/${parseInt(params.id)}`}>
                        View Withdraws Report
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to={`/sacco/withdraws/daily_withdraws/${parseInt(params.id)}`}>
                        View Daily Withdraws
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <Link to={`/sacco/withdraw/daily_withdraws/${parseInt(params.id)}`}>
                        View Daily Savings Report
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* footer............................ */}
        <AdminFooter />
          {/* footer end............................ */}
        <div />
      </div>
    </>
  );
};

export default WithdrawsView;
