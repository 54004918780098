import AdminFooter from "../components/admin_footer";
import AdminSidebar from "../components/admin_sidebar";
import AdminHeader from "../components/admin_header";
import React, { useState, useEffect, useRef } from "react";
// import Loader from "../../services/loaders/loader";
import useAxiosPrivate from "../../middlewares/hooks/useAxiosPrivate";
import Chart from "chart.js/auto";
import { useLocation, useNavigate } from "react-router-dom";

// import ProgressBar from "@badrap/bar-of-progress";
// import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";

const AdminDashboard = () => {
  const controller = new AbortController();
  const [saccos, setSaccos] = useState([]);
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [smsCount, setSmsCount] = useState([]);
  const [allSaccosSmsCount, setAllSaccosSmsCount] = useState([]);
  const [paidSmsCount, setPaidSmsCount] = useState([]);
  const [notPaidCount, setNotPaidCount] = useState([]);
  const [smsAmount, setSmsAmount] = useState([]);
  const [allSaccosSmsAmount, setAllSaccosSmsAmount] = useState([]);
  const [smsPaidAmount, setSmsPaidAmount] = useState([]);
  const [smsIncome, setSmsIncome] = useState([]);
  const [availableIncome, setAvailableIncome] = useState([]);
  const [pendingUssd, setPendingUssd] = useState([]);
  const [allUssd, setAllUssd] = useState([]);
  const [liquidatedUssd, setLiquidatedUssd] = useState([]);
  const [isLoadingSaccos, setIsLoadingSaccos] = useState(true);
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isLoadingSmsCount, setIsLoadingSmsCount] = useState(true);
  const [isLoadingAllSaccosSmsCount, setIsLoadingAllSaccosSmsCount] = useState(true);
  const [isLoadingSmsAmount, setIsLoadingSmsAmount] = useState(true);
  const [isLoadingAllSaccosSmsAmount, setIsLoadingAllSaccosSmsAmount] = useState(true);
  const [isLoadingPaidSmsCount, setIsLoadingPaidSmsCount] = useState(true);
  const [isLoadingPaidSmsAmount, setIsLoadingPaidSmsAmount] = useState(true);
  const [isLoadingSmsIncome, setIsLoadingSmsIncome] = useState(true);
  const [isLoadingAvailableIncome, setIsLoadingAvailableIncome] =
    useState(true);
  const [isLoadingPendingUssd, setIsLoadingPendingUssd] = useState(true);
  const [isLoadingLiquidatedUssd, setIsLoadingLiquidatedUssd] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();

  const chartRef = useRef(null);

  const [data, setData] = useState([
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 40 },
    { value: 25 },
    { value: 40 },
    { value: 30 },
    { value: 30 },
    { value: 40 },
    { value: 25 },
    { value: 40 },
    { value: 60 },
    { value: 30 },
    { value: 40 },
    { value: 25 },
    { value: 25 },
  ]);

  const getSaccos = async () => {
    try {
      const response = await axiosPrivate.get("/saccos", {
        signal: controller.signal,
      });
      setSaccos(response.data.data.rows_returned);
      setIsLoadingSaccos(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const getUsers = async () => {
    try {
      const response = await axiosPrivate.get("/system_users", {
        signal: controller.signal,
      });
      setUsers(response.data.data.rows_returned);
      setIsLoadingUsers(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  const getClients = async () => {
    try {
      const response = await axiosPrivate.get("/all_clients", {
        signal: controller.signal,
      });
      setClients(response.data.data.rows_returned);
      setIsLoadingClients(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  //get all saccos sms wether notpaid or paid

  const getAllSaccosSms = async () => {
    try {
      setIsLoadingAllSaccosSmsAmount(true);
      setIsLoadingAllSaccosSmsCount(true);
      const response = await axiosPrivate.get("/all_sent_saccos_sms", {
        signal: controller.signal,
      });

      setAllSaccosSmsCount(response?.data?.data?.sms_count);
      console.log(response?.data?.data?.sms_count);
      setAllSaccosSmsAmount(response?.data?.data?.total_amount);
      setIsLoadingAllSaccosSmsCount(false);
      setIsLoadingAllSaccosSmsAmount(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  // Get all ussd wether pending or liquidated 
  const getAllUssd = async () => {
    try {
      const response = await axiosPrivate.get(`/all_ussd`);
      setAllUssd(response.data.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // get unpaid sms for all saccos
  const getSms = async () => {
    try {
      const response = await axiosPrivate.get("/all_sms", {
        signal: controller.signal,
      });

      setNotPaidCount(response?.data?.data?.sms_count);
      console.log(response?.data?.data?.sms_count);
      setSmsAmount(response?.data?.data?.total_amount);
      setIsLoadingSmsCount(false);
      setIsLoadingSmsAmount(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  const getPaidSms = async () => {
    try {
      const response = await axiosPrivate.get("/all_paid_sms", {
        signal: controller.signal,
      });
      console.log(response?.data?.data?.sms_count);
      setPaidSmsCount(response?.data?.data?.sms_count);
      setSmsPaidAmount(response?.data?.data?.total_amount);
      console.log(response?.data?.data?.total_amount);
      setIsLoadingPaidSmsCount(false);
      setIsLoadingPaidSmsAmount(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  // get total Prospective/ Expected sms income
  const getSmsIncome = async () => {
    try {
      const response = await axiosPrivate.get("/all_sms_income", {
        signal: controller.signal,
      });
      setSmsCount(response?.data?.data?.sms_count);
      setSmsIncome(response?.data?.data?.total_income);
      console.log(response?.data?.data?.total_income);
      setIsLoadingSmsIncome(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  // get total Received income from the paid SMS from the invoices made
  const getAvailableSmsIncome = async () => {
    try {
      const response = await axiosPrivate.get("/available_income", {
        signal: controller.signal,
      });
      setSmsCount(response?.data?.data?.sms_count);
      setAvailableIncome(response?.data?.data?.total_income);
      console.log(response?.data?.data?.total_income);
      setIsLoadingAvailableIncome(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };

  // get all pending ussd transactions
  const getPendingUssd = async () => {
    try {
      const response = await axiosPrivate.get("/all_ussd_report/1", {
        signal: controller.signal,
      });
      setIsLoadingPendingUssd(true);
      setSmsCount(response?.data?.data?.ussd_count);
      setPendingUssd(response?.data?.data?.total_ussd);
      console.log(response?.data?.data?.total_ussd);
      setIsLoadingPendingUssd(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  // get all pending ussd transactions
  const getLiquidatedUssd = async () => {
    try {
      const response = await axiosPrivate.get("/all_liquidated_ussd_report/1", {
        signal: controller.signal,
      });
      setIsLoadingLiquidatedUssd(true);
      setSmsCount(response?.data?.data?.ussd_count);
      setLiquidatedUssd(response?.data?.data?.total_ussd);
      console.log(response?.data?.data?.total_ussd);
      setIsLoadingLiquidatedUssd(false);
    } catch (error) {
      // console.log(error)
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      }
    }
  };
  useEffect(() => {
    getSaccos();
    getClients();
    getAllSaccosSms();
    getSms();
    getPaidSms();
    getSmsIncome();
    getAvailableSmsIncome();
    getPendingUssd();
    getLiquidatedUssd();
    getUsers();
    getAllUssd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      // Check if there is an existing chart instance and destroy it
      if (chartRef.current.chart) {
        chartRef.current.chart.destroy();
      }

      // Create a new chart instance
      chartRef.current.chart = new Chart(ctx, {
        type: "line",
        data: {
          labels: data.map((item, index) => index + 1),
          datasets: [
            {
              label: "Sacco Today's Transactions",
              data: data.map((item) => item.value),
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1,
            },
          ],
        },
      });
    }
  }, [data]);

  return (
    <>
      {/* .............header...................... */}
      <AdminHeader />
      {/* .............header end..................... */}
      <div className="d-flex align-items-stretch">
        {/* .............siderbar...................... */}
        <AdminSidebar />
        {/* ..............sidebar end..................... */}
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5">
              <div className="row">
                {/* <div className="col-md-6 mb-5">
                  <div className="card font-weight-bold text-white bg-gradient-primary text-uppercase">
                    <div className="card-body">
                      Total saccos : 
                      {isLoadingSaccos ? <Loader /> : <span>{saccos}</span>}
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-6 mb-5">
                  <div className="card font-weight-bold text-white bg-gradient-danger text-uppercase">
                    <div className="card-body">Recently onboarded: </div>
                  </div>
                </div> */}
                <div className="col-xl-3 col-md-6 block_deposits">
                  <div className="card bg-hover-gradient-success  bg-hover-success bg-gradient-success card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-left">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            TOTAL SACCOS
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingSaccos ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <span>{saccos}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 block_deposits">
                  <div className="card bg-hover-gradient-success  bg-hover-success bg-gradient-success card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-left">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            ALL CLIENTS
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingClients ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <span>{clients.toLocaleString()}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6 block_deposits">
                  <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-left">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-uppercase text-white mb-1">
                            SENT SMS COUNT
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingAllSaccosSmsCount ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <span>{allSaccosSmsCount}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 block_deposits">
                  <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-left">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-uppercase text-white mb-1">
                            SMS Amount
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingAllSaccosSmsAmount ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <span>{allSaccosSmsAmount}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 block_deposits  mt-2">
                  <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-left">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-uppercase text-white mb-1">
                            UNPAID SMS COUNT
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingSmsCount ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <span>{notPaidCount}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 block_deposits mt-2">
                  <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new border-left-warning shadow h-100 py-1">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-uppercase text-white mb-1">
                            UNPAID SMS AMOUNT
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingSmsAmount ? (
                              // <Loader />
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <span>{smsAmount}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-6 block_deposits mt-2">
                  <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-uppercase text-white mb-1">
                            PAID SMS COUNT
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingPaidSmsCount ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <span>{paidSmsCount}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 block_deposits mt-2">
                  <div className="card bg-hover-gradient-danger  bg-hover-danger bg-gradient-danger card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-uppercase text-white mb-1">
                            PAID SMS AMOUNT
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingPaidSmsAmount ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <span>{smsPaidAmount}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 block_deposits mt-2">
                  <div className="card bg-hover-gradient-info  bg-hover-primary bg-gradient-primary card-new border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            ACTUAL SMS INCOME
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingSmsIncome ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <span>{smsIncome}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 block_deposits mt-2">
                  <div className="card bg-hover-gradient-info  bg-hover-primary bg-gradient-primary card-new border-left-primary shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            AVAILABLE SMS INCOME
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingAvailableIncome ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <span>{availableIncome}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 block_deposits mt-2">
                  <div className="card bg-hover-gradient-warning  bg-hover-warning bg-gradient-warning card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            TOTAL USSD COUNT
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            <span>{allUssd.ussd_count}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 block_deposits mt-2">
                  <div className="card bg-hover-gradient-warning  bg-hover-warning bg-gradient-warning card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            TOTAL USSD AMOUNT
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            <span>{allUssd.total_amount}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 block_deposits mt-2">
                  <div className="card bg-hover-gradient-warning  bg-hover-warning bg-gradient-warning card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            PENDING USSD
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingPendingUssd ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <span>{pendingUssd}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-md-6 block_deposits mt-2">
                  <div className="card bg-hover-gradient-warning  bg-hover-warning bg-gradient-warning card-new border-left-warning shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-white text-uppercase mb-1">
                            LIQUIDATED USSD
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-white">
                            {isLoadingLiquidatedUssd ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              <span>{liquidatedUssd}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-4 col-md-6 mb-4 block_deposits mt-4">
                  <div className="card card-new border border-success border-3 shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                            TOTAL SACCO MEMBERS
                          </div>
                          <div className="h5 mb-0 font-weight-bold">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-xl-4 col-md-6 mb-4 block_deposits mt-4">
                  <div className="card card-new border border-danger border-3 border-left-danger shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                            TOTAL WITHDRAWS
                          </div>
                          <div className="h5 mb-0 font-weight-bold">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className=" col-md-4 mb-4 block_deposits mt-4">
                  <div className="card card-new border border-info border-3 border-left-info shadow h-100 py-2">
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                            TOTAL DEPOSITS
                          </div>
                          <div className="h5 mb-0 font-weight-bold">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="row mt-3">
                <div className="col-md-4 mt-4">
                  <div className="card font-weight-bold text-white bg-gradient-primary text-uppercase">
                    <div className="card-body">
                      Total SACCO branches : <br></br>
                      <span>{}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 mt-4">
                  <div className="card font-weight-bold text-white bg-gradient-warning text-uppercase">
                    <div className="card-body">
                    Impact {"("}Jobs Created{")"} saccos users
                      <div className="h5 mb-0 font-weight-bold text-white">
                        {isLoadingUsers ? (
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <span>{users}</span>
                        )}
                      </div>
                      {/* <span>{users}</span> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4 mb-5">
                  <div className="card ">
                    <div className="card-body">
                      <canvas ref={chartRef} />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* footer............................ */}
          <AdminFooter />
          {/* footer end............................ */}
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
