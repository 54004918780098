import { useState, useEffect } from "react";
import AdminFooter from "./components/admin_footer";
import AdminSidebar from "./components/admin_sidebar";
import AdminHeader from "./components/admin_header";
import ProgressBar from "@badrap/bar-of-progress";
import Pagination from "./pagination";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../middlewares/hooks/useAxiosPrivate";
import toast, { Toaster } from "react-hot-toast";

const AllUssdReport = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [progress, setProgress] = useState(null);
  const [ussdTransactions, setUssdTransactions] = useState([]);
  const [totalUssdCount, setTotalUssdCount] = useState(0);
  const [ussdAmount, setUssdAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;

  const fetchUssdTransactions = async () => {
    if (progress) {
      progress.start();
      setProgress(new ProgressBar({ size: 5, color: "#e91e63" }));
    }
    try {
      const controller = new AbortController();
      const response = await axiosPrivate.get(`/paginated_all_ussd/${currentPage}`, {
        signal: controller.signal,
      });
      setUssdTransactions(response?.data?.data?.ussdTransactions);
      console.log(response?.data?.data);
      setTotalUssdCount(response?.data?.data?.ussd_count);
      console.log(response?.data?.data?.total_amount);
      setUssdAmount(response?.data?.data?.total_amount);
      if (progress) {
        progress.finish();
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", { state: { from: location }, replace: true });
      } else {
        console.error("Error fetching USSD transactions:", error);
        toast.error("No info found", {
          style: { background: "#e91e63", fontSize: "13px", color: "#fff" },
        });
      }
      if (progress) {
        progress.finish();
      }
    }
  };

  useEffect(() => {
    fetchUssdTransactions();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // This will fetch USSD transactions when the component mounts

  useEffect(() => {
    const pages = Math.ceil(totalUssdCount / itemsPerPage);
    setTotalPages(pages);
  }, [totalUssdCount, itemsPerPage]);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchUssdTransactions(pageNumber);
  };

  // function to export data as pdf 
  return (
    <>
      <Toaster />
      <AdminHeader />
      <div className="d-flex align-items-stretch">
        <AdminSidebar />
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5 mt-3">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-lg-0">
                    <div className="card-header bg-dark text-white">
                      <h2 className="h6 mb-0 text-uppercase text-center">
                        Pending and Liquidated Report
                      </h2>
                    </div>
                    <div className="card-body mt-3 mb-3">
                      <div className="mt-3">
                        <table className="table table-hover table-bordered text-medium-small">
                          <thead>
                            <tr className="bg-dark text-white text-medium-small">
                              <th>Client</th>
                              <th>Account Number</th>
                              <th>Amount</th>
                              <th>Sacco Name</th>
                              <th>Timestamp</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ussdTransactions.map((transaction) => (
                              <tr key={transaction.id}>
                                <td>{transaction.client}</td>
                                <td>{transaction.account_number}</td>
                                <td style={{ textAlign: "right" }}>
                                  {parseInt(
                                    transaction.amount
                                  ).toLocaleString()}
                                </td>
                                <td>{transaction.sacco_name}</td>
                                <td>{transaction.timestamp}</td>
                              </tr>
                            ))}
                            <tr className="bg-dark text-white">
                              <th colSpan="2">Total Ussd Amount</th>
                              <th style={{ textAlign: "right" }}>
                                {ussdAmount}
                              </th>
                              <th colSpan="1">Ussd Count</th>
                              <th>{totalUssdCount}</th>
                            </tr>
                          </tbody>
                        </table>
                        <div className="text-center">
                          <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={totalUssdCount}
                            currentPage={currentPage}
                            onPageChange={onPageChange}
                            totalPages={totalPages}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <AdminFooter />
    </>
  );
};

export default AllUssdReport;
