import { useEffect, useState } from "react";
import AdminFooter from "./components/admin_footer";
import AdminHeader from "./components/admin_header";
import AdminSidebar from "./components/admin_sidebar";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../middlewares/hooks/useAxiosPrivate";

const TransactionsView = () => {
  const params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [saccosName, setSaccosName] = useState("");

  const controller = new AbortController();
  const getSaccosName = async () => {
    try {
      const response = await axiosPrivate.get(`/saccos/${params.id}`, {
        signal: controller.signal,
      });
      setSaccosName(response.data.data.sacco.sacco_name);
    } catch (error) {
      console.error("Error fetching saccos:", error);
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };

  useEffect(() => {
    getSaccosName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/* .............header...................... */}
      <AdminHeader />
      {/* .............header end..................... */}
      <div className="d-flex align-items-stretch">
        <AdminSidebar />
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="text-center card">
                    <div className="card-header text-uppercase">
                      Sacco Name: {saccosName}
                    </div>
                    {/* You can customize how you want to display the sacco name here */}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="text-center card">
                    <div className="card-header text-uppercase">
                      Daily Savings
                    </div>
                    <div className="card-body">
                      <Link
                        className="btn btn-primary"
                        to={`/sacco/savings/daily_savings/${parseInt(
                          params.id
                        )}`}
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="text-center card">
                    <div className="card-header text-uppercase">
                      Daily Withdraws
                    </div>
                    <div className="card-body">
                      <Link
                        className="btn btn-primary"
                        to={`/sacco/withdraws/daily_withdraws/${parseInt(
                          params.id
                        )}`}
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="text-center card">
                    <div className="card-header text-uppercase">Daily USSD</div>
                    <div className="card-body">
                      <Link
                        className="btn btn-danger"
                        to={`/sacco/ussd/daily_ussd/${parseInt(params.id)}`}
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="text-center card">
                    <div className="card-header text-uppercase">Daily SMS</div>
                    <div className="card-body">
                      <Link
                        className="btn btn-danger"
                        to={`/sacco/sms/daily_sms/${parseInt(params.id)}`}
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="text-center card">
                    <div className="card-header text-uppercase">
                      Daily loans disburbed
                    </div>
                    <div className="card-body">
                      <Link
                        className="btn btn-danger"
                        // to={`/sacco/liquidated_ussd_report/${parseInt(params.id)}`}
                      >
                        View
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* footer............................ */}
        <AdminFooter />
        {/* footer end............................ */}
        <div />
      </div>
    </>
  );
};

export default TransactionsView;
