
import AdminFooter from "./components/admin_footer";
import AdminHeader from "./components/admin_header";
import AdminSidebar from "./components/admin_sidebar";
import { useParams,  Link } from "react-router-dom";

const UssdView = () => {
  const params = useParams();
  return (
    <>
      {/* .............header...................... */}
      <AdminHeader />
      {/* .............header end..................... */}
      <div className="d-flex align-items-stretch">
        <AdminSidebar />
        <div className="page-holder w-100 d-flex flex-wrap">
          <div className="container-fluid px-xl-5">
            <section className="py-5">
              <div className="row mb-3">
                <div className="col-md-3 mb-4">
                  <div className="card">
                    <div className="card-body">
                      {/* <Link to={`/sacco/smsusage/sms_report${params.id}`}>View SMS Report</Link> */}
                      <Link to={`/sacco/ussdusage/ussd_report/${parseInt(params.id)}`}>
                        View Ussd Report
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-4">
                  <div className="card">
                    <div className="card-body">
                      {/* <Link to={`/sacco/smsusage/sms_report${params.id}`}>View SMS Report</Link> */}
                      <Link to={`/sacco/ussdusage/liquidate_ussd/${parseInt(params.id)}`}>
                        Liquidate Ussd
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-4">
                  <div className="card">
                    <div className="card-body">
                      {/* <Link to={`/sacco/smsusage/sms_report${params.id}`}>View SMS Report</Link> */}
                      <Link to={`/sacco/ussdusage/failed_ussd/${parseInt(params.id)}`}>
                        View Failed Ussd Report
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-4">
                  <div className="card">
                    <div className="card-body">
                      {/* <Link to={`/sacco/smsusage/sms_report${params.id}`}>View SMS Report</Link> */}
                      <Link to={`/sacco/ussdusage/daily_ussd/${parseInt(params.id)}`}>
                        View daily USSD deposits
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* footer............................ */}
        <AdminFooter />
          {/* footer end............................ */}
        <div />
      </div>
    </>
  );
};

export default UssdView;
